import * as enLang from '@amcharts/amcharts4/lang/en_US';
import * as noLang from '@amcharts/amcharts4/lang/nb_NO';
import * as svLang from '@amcharts/amcharts4/lang/sv_SE';
import * as fiLang from '@amcharts/amcharts4/lang/fi_FI';
import * as daLang from '@amcharts/amcharts4/lang/da_DK';

export let en = enLang;
export let no = noLang;
export let sv = svLang;
export let fi = fiLang;
export let da = daLang;