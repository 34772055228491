import * as am4charts from '@amcharts/amcharts4/charts';
import { Amcharts } from './Amcharts';

export class AmchartsDumbbellColumnchart extends Amcharts {

    constructor() {
        super();
    }

    createByRef(ref: any, context: any) : any {
        let settings = context == null ? null : context.settings;

        this.applyTheme(context, () => {
            this.create(ref, am4charts.XYChart, context);
        });
        this.createValueAxis();
        this.updateValueAxis(settings);
        for (let j = 0; j < context.dimensions.length; j++) {
            let dimension = context.dimensions[j];
            let dimensionResultName = dimension.resultName || "key" + j;
            this.createCategoryAxis(dimensionResultName, context.settings);
        }
        this.updateCategoryAxis(settings);

        for (let j = 0; j < context.dimensions.length; j++) {
            let dimension = context.dimensions[j];
            let dimensionResultName = dimension.resultName || "key" + j;
            let dummy = "";
            let series = this.createSeries(dummy, dimensionResultName, context);
            if (series.columns) {
                series.columns.template.events.on("hit", function (ev: any) {
                    console.log("clicked on ", ev.target.column.dataItem.dataContext);
                    return context.onClickColumnCaller
                        .invokeMethodAsync(context.onClickColumnFn, ev.target.column.dataItem.dataContext)
                        .then((r: any) => console.log(r));
                }, this);
            }
        }

        this.updateMeasures(settings);
        this.updateDimensions(settings);
        return this;
    }

    //recreate(ref: any, context: any) {
    //    this.dispose();
    //    this.createByRef(ref, context);
    //}

    createValueAxis() {
        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.renderer.axisFills.template.disabled = true;
        return valueAxis;
    }

    createCategoryAxis(categoryFieldName: any, _settings: any) {
        let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = categoryFieldName;

        categoryAxis.title.fontWeight = "bold";
        categoryAxis.renderer.cellStartLocation = 0;
        categoryAxis.renderer.cellEndLocation = 1;
        let label = categoryAxis.renderer.labels.template;
        label.tooltipText = "{category}";
        label.truncate = true;
        label.maxWidth = 130;
        label.rotation = -45;
        label.horizontalCenter = "right";

        categoryAxis.renderer.minGridDistance = 30;
        return categoryAxis;
    }

    createSeries(_dummy: any, categoryFieldName: any, context: any) {
        let openValueFieldName = context.measures[0].resultName || "value";
        let closeValueFieldName = context.measures[1].resultName || "value";
        let settings = context == null ? null : context.settings;
        let series = null;
        let template = null;
        series = this.chart.series.push(new am4charts.ColumnSeries());
        series.stacked = settings == null ? false : settings.stacked;
        template = series.columns.template;
        template.fillOpacity = .8;
        series.dataFields.categoryX = categoryFieldName;
        series.dataFields.openValueY = openValueFieldName;
        series.dataFields.valueY = closeValueFieldName;
        template.tooltipText = "open: {openValueY.value} close: {valueY.value}";
        series.sequenceInterpolation = true;
        series.fillOpacity = 0;
        series.strokeOpacity = 1;
        series.columns.template.width = 0.01;
        series.tooltip.pointerOrientation = "horizontal";
        if (series.legendSettings) series.legendSettings.itemValueText = "[bold]{valueY}[bold]";

        let openBullet = series.bullets.create(am4charts.CircleBullet);
        openBullet.locationY = 1;

        let closeBullet = series.bullets.create(am4charts.CircleBullet);
        closeBullet.fill = this.chart.colors.getIndex(4);
        closeBullet.stroke = closeBullet.fill;

        this.updateSeries(context.settings);

        return series;
    }

    updateCategoryAxis(settings: any) {
        if (settings == null) return;
    }

    removeCategoryAxis(index: any) {
        this.chart.xAxes.removeIndex(index);
    }

    updateValueAxis(settings: any) {
        if (settings == null) return;

        let valueAxis = this.chart.yAxes.getIndex(0);
        if (settings.valueAxisTitle) {
            valueAxis.title.text = settings.valueAxisTitle;
            valueAxis.title.fontWeight = "bold";
        }
        valueAxis.min = settings.minValue;
        valueAxis.calculateTotals = settings.percentage;
    }

    getSeriesType(measureName: any, settings: any) {
        if (settings && settings.measures) {
            let measure = settings.measures.find((m: any) => m.name == measureName);
            if (measure) 
                return measure.seriesType;            
        }
    }

    updateSettings(settings: any) {
        if (settings) {
            this.updateCategoryAxis(settings);
            this.updateValueAxis(settings);
            this.updateDimensions(settings);
            this.updateMeasures(settings);
            this.updateSeries(settings);
            if (this.chart.data && this.chart.data.length > 0)
                this.setLegend(settings);            
        }
    }

    updateDimensions(settings: any) {
        if (!settings || !settings.dimensions) {
            return;
        }

        const { xAxes } = this.chart;

        for (let { name, title } of settings.dimensions) {
            for (let i = 0; i < xAxes.length; i++) {
                let xAxis = xAxes.getIndex(i);
                if (xAxis.dataFields.category == name) {
                    xAxis.title.text = title || null;
                }
            }
        }
    }

    updateMeasures(settings: any) {
        if (settings == null) return;

        let measures = settings.measures;
        if (measures && measures.length && measures.find((m: any) => m.title !== '')) {
            let series = this.chart.series.getIndex(0);
            series.name = measures[0].title + " " + measures[1].title;
        }
        else 
            this.removeLegend();
    }

    setData(context: any) {
        if (context.data) {
            this.chart.data = context.data;
            this.setLegend(context.settings);
        }
    }

    markActiveByValue(values: any[]) {
        setTimeout(() => {
            this.chart.series.each((s: any) => s.columns.each((c: any, _i: number) => {
                values.forEach((v: any) => {
                    if (v == c.dataItem.categoryX)
                        c.isActive = true;                    
                })
            }));
        }, 100);
    }
}