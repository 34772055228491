import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as langs from './Langs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { copyImageToClipboard } from 'copy-image-clipboard'

export abstract class Amcharts {
    ref: any;
    chart: any | undefined;
    context: any | undefined;
    indicator: any | undefined;
    fontSize: any | undefined;
    whenReadyFns: any[];
    ready: boolean | undefined;
    defaultLegendTooltipText: string | undefined;
    theme: any | undefined;
    showLabelsFromApp: boolean | undefined;
    colorSetObject!: am4core.ColorSet;

    abstract createByRef(ref: any, context: any): any; // Note: Abstract

    constructor() {
        console.log("Browser language is " + this.getBrowserLanguage());

        this.chart = null;
        this.indicator = null;
        this.fontSize = 13;
        this.defaultLegendTooltipText = '{name}';
        this.whenReadyFns = [];
    }

    create(ref: any, chartType: any, context: any) {
        this.ref = ref;
        this.theme = context.theme;
        this.context = context;

        am4core.options.defaultLocale = this.getLang();
        this.chart = am4core.create(ref, chartType);

        let me = this;
        this.chart.events.on('ready', (ev: any) => {
            me.ready = true;

            while (me.whenReadyFns.length) {
                let fn = me.whenReadyFns?.shift();
                fn(ev);
            }

        });
        this.chart.fontSize = this.fontSize;
        this.chart.numberFormatter.numberFormat = (context.settings && context.settings.numberFormat) ? context.settings.numberFormat : "#,###.";

        if (context.settings.numberStandard == "Default") {
            let bigNumberPrefixes = this.getBigNumberPrefixesLocale();
            this.chart.numberFormatter.bigNumberPrefixes = [
                { "number": 1e+3, "suffix": " " + bigNumberPrefixes.thousand },
                { "number": 1e+6, "suffix": " " + bigNumberPrefixes.million },
                { "number": 1e+9, "suffix": " " + bigNumberPrefixes.billion }
            ];
        }
        this.appendDataValidated();
        if (context.title) {
            let titleItem = this.applyTitle(context.title, true);
            this.appendExportProcessing(titleItem);
        }

        if (context.data)
            this.chart.data = context.data;
    }

    recreate(ref: any, context: any) {
        this.dispose();
        this.createByRef(ref, context);
    }

    dispose() {
        if (this.chart) {
            this.chart.dispose();

            console.log('Chart was disposed ' + this.chart._baseId);
        }
    }

    updateSettings(context: any) {
        this.context = context;
    }

    whenReady(fn: any) {
        if (this.ready)
            fn();
        else
            this.whenReadyFns.push(fn);

    }

    appendDataValidated() {
        this.chart.events.on("datavalidated", (_e: any) => {
            if (this.context?.dimensions?.length > 0) {
                this.updateValueInAxes();
            }
        });
    }

    updateValueInAxes() {
        let axes = this.chart.yAxes || this.chart.xAxes || undefined;
        if (!axes || !axes.getIndex) return;

        let context = this.context;
        //let numMeasures = (context.measures?.length ?? 0) + (context.blendSettings?.length ?? 0);
        let colors = this.getThemeColors(this.context);
        let valueAxes = axes.getIndex(0)?.className === 'ValueAxis' ? this.chart.yAxes : this.chart.xAxes;

        valueAxes.each((valueAxis: any, i: number) => {
            if (valueAxes.length == 1)
                valueAxis.strictMinMax = true;

            const blendSetting = context.blendSettings?.find((b: any) => {
                return b.measures.find((m: any) => {
                    return m.name === valueAxis.dataFields.resultName;
                });
            });

            let isPercentFormat = valueAxis.numberFormatter.numberFormat.includes('%');
            let axisMaxValue = !blendSetting ? context.settings.axisMaxValue : blendSetting.axisMaxValue;
            let axisMinValue = !blendSetting ? context.settings.axisMinValue : blendSetting.axisMinValue;
            axisMaxValue = isPercentFormat && axisMaxValue != null ? axisMaxValue / 100.0 : axisMaxValue;
            axisMinValue = isPercentFormat && axisMinValue != null ? axisMinValue / 100.0 : axisMinValue;
            let maxValue = !blendSetting ? context.settings.maxValue : blendSetting.maxValue;
            let minValue = !blendSetting ? context.settings.minValue : blendSetting.minValue;
            let isSyncAxesByZero = !blendSetting ? context.settings.isSyncAxesByZero : blendSetting.isSyncAxesByZero;

            if (valueAxes.length > 1) {
                valueAxis.renderer.labels.template.fill = colors[i];
                valueAxis.title.fill = colors[i];
            }

            valueAxis.max = axisMaxValue;
            valueAxis.min = axisMinValue;
            if (blendSetting) {
                valueAxis.renderer.grid.template.disabled = true;
                valueAxis.strictMinMax = true;
            }

            if (axisMaxValue == null)
                valueAxis.extraMax = (maxValue ?? 10) / 100.0;

            if (axisMinValue == null) {
                if (valueAxes.length == 1 || !isSyncAxesByZero)
                    valueAxis.extraMin = minValue / 100.0;
            }
        });
    }

    appendExportProcessing(titleItem: any) {
        let isTitleItemDisabled = titleItem.disabled;

        this.chart.exporting.events.on("exportstarted", function (_ev: any) {
            titleItem.disabled = false;
            titleItem.parent.invalidate();
        });
        this.chart.exporting.events.on("exportfinished", (_ev: any) => {
            titleItem.disabled = isTitleItemDisabled;
        });
    }

    applyTitle(title: string, exportOnly: boolean): any {
        let titleItem = this.chart.titles.create();
        titleItem.text = "[bold]" + title + "[/]";
        titleItem.marginTop = 15;
        titleItem.marginBottom = 15;
        titleItem.disabled = exportOnly;

        this.chart.exporting.validateSprites.push(titleItem);
        this.chart.exporting.validateSprites.push(titleItem.parent);

        this.chart.exporting.filePrefix = title;

        return titleItem;
    }

    applyColorSet(settings: any) {
        this.colorSetObject.list = this.getColors(settings);
        this.colorSetObject.startIndex = 0;
        this.chart.colors = this.colorSetObject;
    }

    applyTheme(context: any, callback: { (): void; (): void; (): void; (): void; }) {
        if (context.theme) {
            this.theme = context.theme;

            let me = this;
            let colors = this.getThemeColors(context);

            function am4themes_mudTheme(target: any) {
                target.list = colors;
                target.reuse = true;
                if (target instanceof am4core.InterfaceColorSet) {
                    if (me.theme !== null) {
                        let style = getComputedStyle(document.body);
                        let textColor = style.getPropertyValue('--mud-palette-text-primary').trim();
                        target.setFor("text", am4core.color(textColor));
                        target.setFor("grid", am4core.color(textColor));
                    }
                }
            }
            let mudtheme = am4themes_mudTheme;
            if (mudtheme) { am4core.useTheme(mudtheme); }
        }
        callback();
    }

    getThemeColors(context: any) {
        let me = this;
        let settings = context.settings || {};
        let firstDimension = context.settings?.dimensions == undefined ? null : context.settings?.dimensions[0];
        let orderedColors = me.getOrderedColors(me.getColors(settings), firstDimension?.theme);
        return firstDimension?.useGradient ? me.getGradientColors(orderedColors[0], firstDimension.gradientStep) : orderedColors;
    }

    getColors(settings: any) {
        let theme = this.theme;
        theme.palette = Object.keys(theme.palette).length === 0 ? theme.defaultPalette : theme.palette;
        let isDarkTheme = theme.name == 'Dark';

        if (isDarkTheme && settings.useDarkInDarkMode)
            return this.createColors(theme?.mudPalette?.dark);

        if (theme.name == 'Custom')
            return this.getPaletteColors(theme);

        return isDarkTheme ? this.getDefaultDarkColors(theme) : this.getDefaultLightColors(theme);
    }
    createColors(color: any) {
        let delta = 0.2;
        let lighten = 0.05;
        let numColors = 5;
        let list = [];
        for (let i = 0; i < numColors; i++) {
            let lightenColor = am4core.color(color).lighten(lighten);
            list.push(lightenColor);
            lighten += delta;
        }
        return list;
    }

    getOrderedColors(colors: any, theme: any) {
        if (theme != null) {
            let chooseColorFill = function (_theme: string) {
                switch (_theme) {
                    case 'secondary': return 1;
                    case 'tertiary': return 2;
                    case 'fourthiary': return 3;
                    case 'fifthiary': return 4;
                    case 'sixtiary': return 5;
                    default: return 0;
                }
            };

            let visualColorInx = chooseColorFill(theme);
            let selectedColor = colors[visualColorInx];
            let firstColor = colors[0];

            colors[0] = selectedColor;
            colors[visualColorInx] = firstColor;
        }
        return colors;
    }

    getPaletteColors(theme: any) {
        let colorsArr: any[] = [];
        const lightenValues = [0, 0.3, 0.5, 0.7, 0.9, 0.1];
        for (let i = 0; i < 6; i++) {
            colorsArr.push(am4core.color(theme?.palette?.Primary).lighten(lightenValues[i]));
            colorsArr.push(am4core.color(theme?.palette?.Secondary).lighten(lightenValues[i]));
            colorsArr.push(am4core.color(theme?.palette?.Tertiary).lighten(lightenValues[i]));
            colorsArr.push(am4core.color(theme?.palette?.Fourthiary).lighten(lightenValues[i]));
            colorsArr.push(am4core.color(theme?.palette?.Fifthiary).lighten(lightenValues[i]));
            colorsArr.push(am4core.color(theme?.palette?.Sixtiary).lighten(lightenValues[i]));
        }

        return colorsArr;
    }

    getCustomDarkColors(theme: any) {
        return this.getDefaultDarkColors(theme);
    }

    getGradientColors(color: any, gradientStep: any) {
        let colors = [am4core.color(color)];
        let step = 0;

        for (let i = 1; i < 100 / gradientStep; i++) {
            step += gradientStep / 100;
            colors.push(am4core.color(color).lighten(step));
        }

        return colors;
    }

    getDefaultLightColors(theme: any) {
        let colorsArr: any[] = [];
        const lightenValues = [0, 0.3, 0.5, 0.7, 0.9, 0.1];
        for (let i = 0; i < 6; i++) {
            colorsArr.push(am4core.color(theme?.defaultPalette?.Primary).lighten(lightenValues[i]));
            colorsArr.push(am4core.color(theme?.defaultPalette?.Secondary).lighten(lightenValues[i]));
            colorsArr.push(am4core.color(theme?.defaultPalette?.Tertiary).lighten(lightenValues[i]));
            colorsArr.push(am4core.color(theme?.defaultPalette?.Fourthiary).lighten(lightenValues[i]));
            colorsArr.push(am4core.color(theme?.defaultPalette?.Fifthiary).lighten(lightenValues[i]));
            colorsArr.push(am4core.color(theme?.defaultPalette?.Sixtiary).lighten(lightenValues[i]));
        }

        return colorsArr;
    }

    getDefaultDarkColors(theme: any) {
        let colorsArr: any[] = [];
        const brightenValues = [0, 0.3, 0.5, 0.7, 0.9, 0.1];
        for (let i = 0; i < 6; i++) {
            colorsArr.push(am4core.color(theme?.defaultPalette?.Primary).lighten(brightenValues[i]));
            colorsArr.push(am4core.color(theme?.defaultPalette?.Secondary).lighten(brightenValues[i]));
            colorsArr.push(am4core.color(theme?.defaultPalette?.Tertiary).lighten(brightenValues[i]));
            colorsArr.push(am4core.color(theme?.defaultPalette?.Fourthiary).lighten(brightenValues[i]));
            colorsArr.push(am4core.color(theme?.defaultPalette?.Fifthiary).lighten(brightenValues[i]));
            colorsArr.push(am4core.color(theme?.defaultPalette?.Sixtiary).lighten(brightenValues[i]));
        }

        return colorsArr;
    }

    setTheme(theme: any) {
        this.theme = theme;
    }
    getTheme(settings: any) {
        let firstDimension = settings?.dimensions == undefined ? null : settings?.dimensions[0];
        return firstDimension?.theme || 'primary';
    }

    export(exportType: string, context: any) {
        switch (exportType) {
            case "xlsx":
                this.exportExcel(context);
                break;
            case "imageToClipboard":
                this.exportImageToClipboard(context);
                break;
            default:
                this.chart.exporting.export(exportType, context);
        }
    }

    exportExcel(context: any) {
        let workbook = XLSX.utils.book_new();
        workbook.SheetNames.push("Sheet1");

        let dataCopy = JSON.parse(JSON.stringify(this.chart.data));

        this.removeUnderscoreKeysExceptOfDelta(dataCopy);
        this.removeRelatedToDeltaKeys(dataCopy);

        dataCopy = this.replaceFields(dataCopy, context.fields);

        this.parseDateValues(dataCopy);

        const isComparisonMonth = this.context.dimensions.some((e: any) => e.periodComparison == 7) && this.context.dimensions.some((e: any) => e.bin == 5);
        const sheetConfig = isComparisonMonth ? { dateNF: 'MMM', UTC: true } : { UTC: true };
        workbook.Sheets["Sheet1"] = XLSX.utils.json_to_sheet(dataCopy, sheetConfig);

        const workbookFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(workbookFile)], { type: "application/octet-stream" }), `${context.title}.xlsx`);
    }

    exportImageToClipboard(context: any) {
        let titleItem = this.applyTitle(context.title, false);
        this.chart.exporting.getImage("png")
            .then((data: any) => copyImageToClipboard(data))
            .then(() => titleItem.disabled = true);
    }

    removeUnderscoreKeysExceptOfDelta(data: any[]) {
        data.forEach((obj: any) => {
            Object.keys(obj).forEach((key: any) => {
                if (key.startsWith("_") && !key.startsWith("_delta")) {
                    delete obj[key];
                }
            });
        });
    }

    removeRelatedToDeltaKeys(data: any[]) {
        data.forEach((obj: any) => {
            let itemsToRemove: string[] = [];

            Object.keys(obj).forEach((key: any) => {
                if (key.startsWith("_delta_")) {
                    const baseName = key.substring(7); // Remove "_delta_"
                    itemsToRemove.push(baseName);
                }
            });

            Object.keys(obj).forEach((key: any) => {
                if (itemsToRemove.some(x => x == key)) {
                    delete obj[key];
                }
            });
        });
    }

    replaceFields(data: any[], fields: any[]) {
        let resultData: any = [];
        data.forEach((obj: any) => {
            let str = JSON.stringify(obj);
            fields.forEach((field: any) => {
                const re = new RegExp(`:${field.resultName}`, "g");
                str = str.replace(re, '');
                str = str.replace(this.getSearchValue(field), this.getReplaceValue(field));
            });
            resultData.push(JSON.parse(str));
        });
        return resultData;
    }

    getSearchValue(field: any) {
        //refers to enum { Normal = 0, DeltaValue = 1, DeltaPercent = 2 }
        if (field.valueKind === 1 || field.valueKind === 2) {
            return `_delta_${field.resultName}`;
        }
        return field.resultName;
    }

    getReplaceValue(field: any) {
        if (field.legendTitle) return field.legendTitle;
        if (field.metadataTitle) return field.metadataTitle;

        if (field.valueKind === 1 || field.valueKind === 2) {
            return `Delta of ${field.name}`;
        }
        return field.name;
    }


    parseDateValues(data: any[]) {
        data.forEach((d: any) => {
            for (const [key, value] of (Object as any).entries(d)) {
                let is_date = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/gm;
                if (is_date.test(value))
                    d[key] = new Date(value);
            }
        })
    }

    s2ab(s: any) {
        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    showIndicator(_context: any) {
        this.ref.previousElementSibling.style.display = 'block';
    }

    hideIndicator() {
        this.ref.previousElementSibling.style.display = 'none';
    }

    updateSeries(context: any) {
        if (context == null) return;
        if (context.settings == null) return;
        let settings = context.settings;
        this.chart.series.each((s: any) => { s.stacked = settings.stacked; s.calculatePercent = settings.percentage });
        this.chart.invalidateData();
    }

    removeSerieses() {
        while (this.chart.series.length) {
            this.removeSeries(0);
        }
    }
    removeSeries(index: any) {
        if (this.chart.series.length > index) {
            this.chart.series.removeIndex(index).dispose();
        }
    }

    appendColumnActiveState(series: any) {
        let activeState = series.columns.template.states.create("active");
        let baseColor = new am4core.ColorSet().baseColor;
        activeState.properties.fill = baseColor.lighten(-0.2);
        activeState.propertiesstroke = baseColor.lighten(-0.2);
    }

    onClickSliceHandler(_chart: any, series: any, context: any) {
        if (!context.disableInteraction) {
            if (series.slices && context.onClickColumnCaller) {
                series.slices.template.events.on("hit", function (ev: any) {
                    if (ev.target.dataItem.dataContext.sliceGrouperOther)
                        return;
                    let clickContext = JSON.parse(JSON.stringify(ev.target.dataItem.dataContext));
                    clickContext.active = ev.target.isActive;
                    return context.onClickColumnCaller
                        .invokeMethodAsync(context.onClickColumnFn, clickContext);
                }, this);
            }
        }
    }

    onClickColumnHandler(chart: any, series: any, context: any) {
        if (context.disableInteraction)
            return;

        if (!series.columns || !context.onClickColumnCaller)
            return;

        series.columns.template.events.on("hit", function (ev: any) {
            chart.series.each((s: any) => {
                if (!(s instanceof am4charts.ColumnSeries))
                    return;

                s.columns.each(function (column: any) {
                    let columnCategory = column.dataItem.categoryX || column.dataItem.categoryY;
                    let clickItemCategory = ev.target.dataItem.categoryX || ev.target.dataItem.categoryY;

                    if (columnCategory === clickItemCategory)
                        column.isActive = !column.isActive;
                })
            });

            let clickContext = JSON.parse(JSON.stringify(ev.target.dataItem.dataContext));
            clickContext.active = ev.target.isActive;

            context.onClickColumnCaller.invokeMethodAsync(context.onClickColumnFn, clickContext);
        }, this);
    }

    appendThreshold(context: any, series: any, valueFieldName: any) {
        let settings = context == null ? null : context.settings;

        let measure = context.isCluster ? settings?.measures[0] : settings?.measures?.find((m: any) => m.name == valueFieldName);
        if (measure?.threshold) {
            this.appendColumnFill(series, measure);
            if (measure.thresholdLine) {
                let valueAxes = this.getValueAxes();
                let valueAxis = valueAxes?.values?.find((axis: any) => axis.dataFields.resultName === valueFieldName);
                this.lineRange(valueAxis, measure);
            }
        }
    }

    setPercentageAxis(valueAxis: any) {
        valueAxis.numberFormatter.numberFormat = "#'%'";
        valueAxis.strictMinMax = true;
        valueAxis.calculateTotals = true;
        valueAxis.renderer.minWidth = 50;
        return valueAxis;
    }

    getValueAxes(): any {
        if (this.chart.xAxes?.getIndex(0).className === 'ValueAxis') { return this.chart.xAxes; }
        if (this.chart.yAxes?.getIndex(0).className === 'ValueAxis') { return this.chart.yAxes; }
        return null;
    }

    createLegend(): any {
        let legend = new am4charts.Legend();
        legend.useDefaultMarker = true;
        return legend;
    }

    updateLegend() {
        if (this.chart.legend) {
            this.chart.legend.invalidateRawData();

            if (this.context.disableInteraction) {
                this.chart.legend.itemContainers.template.clickable = false;
                this.chart.legend.itemContainers.template.focusable = false;
            }
        }
    }

    setLegend(settings: any) {
        if (!settings) return;

        if (!settings.legendPosition || settings.legendPosition == 'none') {
            this.removeLegend();
            return;
        }

        if (!this.chart.legend) this.chart.legend = this.createLegend();

        const isPositionOnSide = settings.legendPosition == 'left' || settings.legendPosition == 'right';
        this.chart.legend.position = settings.legendPosition;
        this.chart.legend.valign = "top";
        this.chart.legend.align = "center";
        this.chart.legend.contentAlign = "center";
        let legendWidth = isPositionOnSide ? 200 : am4core.percent(100);
        this.chart.legend.width = settings.legendWidth > 0 ? settings.legendWidth : legendWidth;
        this.chart.legend.maxHeight = 100;

        if (settings.legendLabelsWidth > 0) {
            this.chart.legend.labels.template.maxWidth = settings.legendLabelsWidth;
            this.chart.legend.labels.template.width = settings.legendLabelsWidth;
        }

        this.chart.legend.labels.template.truncate = settings.legendLabelsTruncate;
        this.chart.legend.itemContainers.template.tooltipText = this.defaultLegendTooltipText;
        this.chart.legend.itemContainers.template.marginBottom = 10;
        this.chart.legend.valueLabels.template.align = "right";
        this.chart.legend.valueLabels.template.textAlign = "end";
        this.chart.legend.scrollable = true;
        this.chart.legend.maxHeight = null;
        this.chart.legend.itemContainers.template.togglable = true;
        this.updateLegend();

        this.chart.legend.itemContainers.template.events.on("hit", (_ev: any) => {
            let valueAxes = this.getValueAxes();
            if (valueAxes != null)
            {
                let isSingleValueAxis = valueAxes.length === 1;
                let name = _ev.target.dataItem.name;
                let isShowAsPercentage = this.context.measures.some((m: any) => m.showPercentage);
                let valueAxis = isSingleValueAxis ? valueAxes.values[0] : valueAxes.values.find((v: any) => v.dataFields.name == name);
                let series = isSingleValueAxis ? this.chart.series : valueAxis.series;
                let currentSeries = series.values.find((v: any) => v.name == name);
                let visibleSeries = series.values.filter((series: any) => !series.isHidden || (series.name == currentSeries.name && series.isHidden));
                let isOneVisibleSerie = visibleSeries.length == 1;

                if (isSingleValueAxis)
                    valueAxis.keepSelection = false;

                let min = Number.POSITIVE_INFINITY;
                let max = Number.NEGATIVE_INFINITY;

                visibleSeries.forEach((series: any) => {
                    let minVal = series.dataItem.values["valueY"].low ?? series.dataItem.values["valueX"].low;
                    let maxVal = series.dataItem.values["valueY"].high ?? series.dataItem.values["valueX"].high;

                    if (minVal !== undefined && minVal < min)
                        min = minVal;

                    if (maxVal !== undefined && maxVal > max)
                        max = maxVal;
                });

                valueAxis.min = min;
                valueAxis.max = max;

                if (isShowAsPercentage && isOneVisibleSerie && !currentSeries.ignoreMinMax)
                    valueAxis.extraMin = min / 100.0;

                valueAxis.zoomable = false;
                valueAxis.toggleZoomOutButton = false;
                valueAxis.strictMinMax = false;
                currentSeries.ignoreMinMax = !currentSeries.ignoreMinMax;

                if (!isSingleValueAxis) {
                    let valueAxeses = this.chart.yAxes.length > this.chart.xAxes.length ? this.chart.yAxes : this.chart.xAxes;
                    this.baseSyncValueAxes(valueAxeses, valueAxes.values[0]);
                }

                this.chart.invalidateData();

                if (isOneVisibleSerie && !isShowAsPercentage)
                    this.updateValueInAxes();
            }            
        });
    }

    removeLegend() {
        if (this.chart.legend) {
            this.chart.legend.dispose();
            this.chart.legend = undefined;
        }
    }

    getMeasureNumberFormat(measure: any, settings: any): any {
        let visualFormat = settings.numberFormat ?? "#,###.";
        let numberFormat = measure?.numberFormat && measure?.numberFormat !== "not_set"
            ? measure?.numberFormat
            : settings.numberFormat ?? "#,###.";
        return numberFormat == "Default" ? visualFormat : numberFormat;
    }

    getMeasureFixedBigNumberFormat(measure: any, settings: any): any {
        let visualFormat = settings.fixedBigNumberFormat ?? -1;
        let numberFormat = measure?.fixedBigNumberFormat && measure?.fixedBigNumberFormat !== -1
            ? measure?.fixedBigNumberFormat
            : settings.fixedBigNumberFormat ?? -1;
        return numberFormat == -1 ? visualFormat : numberFormat;
    }

    getDimensionNumberFormat(dimension: any): any {
        let numberFormat = dimension?.numberFormat ?? "#.";
        return numberFormat === "Default" ? "#." : numberFormat;
    }

    getSiFormat() {
        return { thousand: "T", million: "M", billion: "G" }
    }

    getBigNumberPrefixes(measureAppearance: any, settings: any) {
        let bigNumberPrefixes = this.getSiFormat();

        if ((measureAppearance?.numberFormat !== "not_set" && measureAppearance?.numberStandard === "Default") || (settings?.numberFormat !== "" && settings?.numberStandard === "Default"))
            bigNumberPrefixes = this.getBigNumberPrefixesLocale();

        return [
            { "number": 1e+3, "suffix": " " + bigNumberPrefixes.thousand },
            { "number": 1e+6, "suffix": " " + bigNumberPrefixes.million },
            { "number": 1e+9, "suffix": " " + bigNumberPrefixes.billion }
        ];
    }


    lineRange(valueAxis: any, measure: any) {
        if (!(valueAxis && measure.thresholdCustomValue && measure.thresholdColor)) { return; }

        let range = valueAxis.axisRanges.create();
        range.value = measure.thresholdCustomValue;
        range.grid.stroke = am4core.color(measure.thresholdColor);
        range.grid.strokeWidth = 2;
        range.grid.strokeOpacity = 1;
        range.grid.above = true;
    }
    appendRanges(valueAxis: any, series: any, measure: any) {
        let range = valueAxis.createSeriesRange(series);
        range.value = 0;
        range.endValue = measure.thresholdCustomValue;
        range.contents.stroke = am4core.color(measure.thresholdColor);
        range.contents.fill = range.contents.stroke;
    }


    appendColumnFill(series: any, measure: any) {
        let me = this;

        if (measure.thresholdType === 'datetime') {
            series.columns.template.adapter.add("fill", (fill: any, target: any) => me.datetimeColumnFill(fill, target, measure));
            series.columns.template.adapter.add("stroke", (stroke: any, target: any) => me.datetimeColumnFill(stroke, target, measure));
        }
        else if (measure.thresholdType === 'custom') {
            series.columns.template.adapter.add("fill", (fill: any, target: any) => me.customColumnFill(fill, target, measure));
            series.columns.template.adapter.add("stroke", (stroke: any, target: any) => me.customColumnFill(stroke, target, measure));
        }
        else {
            series.columns.template.adapter.add("fill", (fill: any, target: any) => me.negativeColumnFill(fill, target, measure));
            series.columns.template.adapter.add("stroke", (stroke: any, target: any) => me.negativeColumnFill(stroke, target, measure));
        }
    }

    datetimeColumnFill(fill: any, target: any, measure: any) {
        let date = new Date(measure.thresholdSelectedDate);
        date.setHours(0, 0);
        if (!target.dataItem || target.dataItem.dateX < date)
            fill.alpha = 1;

        return fill;
    }

    negativeColumnFill(fill: any, target: any, measure: any) {
        const dataValue = target.dataItem?.valueY ?? target.dataItem?.valueX;
        if (dataValue >= 0) return fill;

        return am4core.color(measure.thresholdColor);
    }

    customColumnFill(fill: any, target: any, measure: any) {
        const dataValue = target.dataItem?.valueY ?? target.dataItem?.valueX;
        if (dataValue >= measure.thresholdCustomValue || !target.dataItem)
            return fill;

        return am4core.color(measure.thresholdColor);
    }

    appendLineFill(series: any, measure: any) {

        const datetimeLineFill = (fill: any, target: any) => {
            let date = new Date(measure.thresholdSelectedDate);
            date.setHours(0, 0);
            if (target.dataItem && (target.dataItem.dateX >= date))
                return am4core.color(measure.thresholdColor);
            else
                return fill;
        };

        const lineFill = datetimeLineFill;
        if (lineFill)
            series.segments.template.adapter.add("stroke", lineFill);
    }

    getLabelCenter(rotation: number): { horizontalCenter: string, verticalCenter: string } {
        switch (rotation) {
            case 90:
                return { horizontalCenter: "top", verticalCenter: "middle" };
            case -90:
                return { horizontalCenter: "right", verticalCenter: "middle" };
            case 45:
                return { horizontalCenter: "left", verticalCenter: "middle" };
            case -45:
                return { horizontalCenter: "right", verticalCenter: "middle" };
            default:
                return { horizontalCenter: "middle", verticalCenter: "middle" };
        }
    }

    markActiveByValue(_values: any[]) {
        return;
    }

    getMarkedMultipleAxes(valueAxes: any, measureSettingsWithOwnAxes: any, options?: any): any[] {
        const ownAxes = measureSettingsWithOwnAxes;
        const defaultMode = "general";
        const mode: string = options?.mode ?? defaultMode;

        return valueAxes?.values?.map((axis: any) => {
            const filteredOwnAxes = ownAxes?.filter((ownAxis: any) => axis.dataFields.resultName === ownAxis.name);
            const areAxesExist = filteredOwnAxes?.length || options?.measuresSettingsWithDeltaAxes?.filter((deltaAxis: any) => axis.dataFields.resultName === deltaAxis.resultName)?.length;

            if (areAxesExist)
                return mode === defaultMode ? axis : null;
            else
                return mode === defaultMode ? null : axis;
        });
    }

    getActiveAxis(valueAxes: any, valueFieldName: any) {
        const activeAxis = valueAxes?.values?.find((axis: any) =>
            axis.dataFields.resultName === valueFieldName || axis.dataFields.deltaName === valueFieldName);

        if (activeAxis !== null && activeAxis !== undefined)
            return activeAxis;

        return valueAxes?.values[0];
    }

    baseSyncValueAxes(valueAxes: any, mainAxis: any) {
        let maxValue = this.context.settings?.maxValue ?? 10;
        if (valueAxes.length === 1) {
            valueAxes.values[0].syncWithAxis = undefined;
            this.setExtraMaxForAxis(valueAxes.values[0], maxValue);
            valueAxes.values[0].keepSelection = true;
            return;
        }
        if (mainAxis !== undefined && mainAxis !== null) {
            mainAxis.syncWithAxis = undefined;
            this.setExtraMaxForAxis(mainAxis, maxValue);
            mainAxis.keepSelection = true;
            valueAxes.each((valueAxis: any) => {
                if (mainAxis !== valueAxis) {
                    valueAxis.keepSelection = false;
                    this.setExtraMaxForAxis(valueAxis, maxValue);
                    valueAxis.syncWithAxis = mainAxis;
                }
            });
        }
    }

    setExtraMaxForAxis(axis: any, maxValue: any) {
        if (this.context.settings?.axisMaxValue == null)
            axis.extraMax = maxValue / 100.0;
    }

    getSeriesName(measure: any) {
        if (measure?.name === measure?.resultName)
            return measure?.name;
        else
            return measure?.name || "Count";
    }

    baseGetMinValueForDeltaAxes(context: any): any {
        let data = context.data;
        let min = Infinity;

        if (data) {
            let keys = Object.keys(data?.[0]);
            for (let key in keys) {
                let prop = keys[key];
                if (prop.includes('_delta_value') && !prop.includes('disabled')) {
                    let currentValues: any = [];
                    for (let d in data) {
                        let value = data[d];
                        currentValues.push(value[prop]);
                    }
                    let localMin = Math.min(...currentValues);
                    if (localMin < min)
                        min = localMin;
                }
            }
        }

        return min;
    }

    baseGetMaxValueForDeltaAxes(context: any): any {
        let data = context.data;
        let max = -Infinity;

        if (data) {
            let keys = Object.keys(data?.[0]);
            for (let key in keys) {
                let prop = keys[key];
                if (prop.includes('_delta_value') && !prop.includes('disabled')) {
                    let currentValues: any = [];
                    for (let _key in data) {
                        let value = data[_key];
                        currentValues.push(value[prop]);
                    }
                    let localMax = Math.max(...currentValues);
                    if (localMax > max)
                        max = localMax;
                }
            }
        }

        return max;
    }

    baseSyncAxesByZero(context: any, axes: any) {
        if (!context.settings.isSyncAxesByZero) {
            axes.values?.forEach((axis: any) => {
                axis.min = undefined;
                axis.max = undefined;
            });
            return;
        }

        const data = context.data;
        if (!data || axes.values?.length <= 1)
            return;

        const allValues = this.getAllValues(data, axes);
        const globalMin = Math.min(...allValues);
        const globalMax = Math.max(...allValues);

        axes.values?.forEach((axis: any) => {
            const currentValues = this.getValuesByProp(
                data,
                axis.dataFields.hasDelta ? axis.dataFields.deltaName : axis.dataFields.resultName
            );
            if (globalMin >= 0) {
                const max = Math.max(...currentValues);
                axis.max = max;
                axis.min = 0;
            } else if (globalMax <= 0) {
                const min = Math.min(...currentValues);
                axis.max = 0;
                axis.min = min;
            } else {
                const absMax = Math.max(...currentValues.map((value: any) => Math.abs(value)));
                axis.max = absMax;
                axis.min = -absMax;
            }
        });
    }

    getValuesByProp(data: any, prop: any) {
        let currentValues: any = [];
        for (let key in data) {
            let value = data[key];
            currentValues.push(value[prop]);
        }
        return currentValues;
    }

    getAllValues(data: any, axes: any) {
        let allValues: any = [];
        for (let key in data) {
            let value = data[key];
            axes.values?.forEach((axis: any) => {
                allValues.push(value[axis.dataFields.hasDelta ? axis.dataFields.deltaName : axis.dataFields.resultName]);
            });
        }
        return allValues;
    }

    getActiveLanguage() {
        return !this.context.applicationLanguage || this.context.applicationLanguage.length === 0 ? this.getBrowserLanguage() : this.context.applicationLanguage;
    }

    getBrowserLanguage() {
        return navigator.language || (navigator?.languages?.length && navigator.languages[0]) || 'en'
    }

    getLang() {
        switch (this.getActiveLanguage()) {
            case "no": return langs.no.default;
            case "nb": return langs.no.default;
            case "nn": return langs.no.default;
            case "sv": return langs.sv.default;
            case "sv-SE": return langs.sv.default;
            case "fi": return langs.fi.default;
            case "da": return langs.da.default;
            default: return langs.en.default;
        }
    }

    getLanguageAbbreviation(language: any) {
        switch (language.includes('nb')) {
            case 'nn':
            case 'no':
            case 'nb':
                return 'nb';
            case 'sv':
            case 'sv-SE':
                return 'sv';
            case 'ru':
                return 'ru';
            default:
                return "default";
        }
    }

    getBigNumberPrefixesLocale() {
        let language = this.getActiveLanguage() ?? this.getLanguageAbbreviation(this.getActiveLanguage());
        switch (language) {
            case 'nn':
            case 'no':
            case 'nb':
                return { billion: 'mrd.', million: 'mill.', thousand: 'T' };
            case 'sv':
            case 'sv-SE':
                return { billion: 'mdr', million: 'm', thousand: 'T' };
            case 'da':
                return { billion: 'mia.', million: 'mio.', thousand: 'T' };
            case 'fi':
                return { billion: 'mrd.', million: 'milj.', thousand: 'T' };
            case 'ru':
                return { billion: 'млрд', million: 'млн', thousand: 'тыс' };
            default:
                return { billion: 'B', million: 'M', thousand: 'T' };
        }
    }

    setLabelsStatus(showLabels: boolean) {
        this.showLabelsFromApp = showLabels;
    }

    isNeedToShowLabels(showLabelsFromApp: any, showLabelsByDefault: any) {
        return showLabelsFromApp === undefined ? showLabelsByDefault : showLabelsFromApp;
    }

    percentSignIfNeeded(numberFormat: any, measure: any, canUseDelta: any, basicMeasures: any, isCluster: any, i: any) {
        let isDeltaPercent = measure?.valueKind == 2;
        if (numberFormat?.endsWith('%')) return "";
        if (isCluster && i !== undefined && basicMeasures.length > 0 && basicMeasures[i]?.valueKind == 2) return "%";
        if (canUseDelta && isDeltaPercent) return "%";
        return "";
    }

    showValueAxisTooltip(valueAxis: any, shouldShowTooltip: any) {
        if (!shouldShowTooltip)
            valueAxis.cursorTooltipEnabled = false;
    }

    shouldUseCursor(context: any) {
        return context.settings.showMultiTooltips || context.settings.showValueAxisTooltip || (context.settings?.stacked && context.settings.showLegendPercent);
    }

    translate(model: any, propName: string, lang: string) {
        let propValue = model?.[`${propName}`];
        let glossary = model?.[`${propName}Glossary`];
        let translation = glossary?.translations?.keywords?.find((x: any) => x.language === lang);
        return translation?.text ?? propValue;
    }

    translateFromSettings(context: any, propName: string) {
        return this.translate(context?.settings, propName, context?.currentLanguage?.key);
    }

    translateFromMeasure(context: any, measureAppearance: any, propName: string) {
        return this.translate(measureAppearance, propName, context?.currentLanguage?.key);
    }

    traslateFromDimension(context: any, dimensionAppearance: any, propName: string) {
        return this.translate(dimensionAppearance, propName, context?.currentLanguage?.key);
    }

    getPrefix(context: any, measureAppearance: any, settings: any) {
        let measurePrefix = this.translateFromMeasure(context, measureAppearance, "prefix");
        if (measurePrefix)
            return `${measurePrefix} `;

        let settingsPrefix = this.translateFromSettings(context, "prefix");
        if (settingsPrefix)
            return `${settingsPrefix} `;

        return "";
    }

    getSuffix(context: any, measureAppearance: any, settings: any) {
        let measureSuffix = this.translateFromMeasure(context, measureAppearance, "suffix");
        if (measureSuffix)
            return ` ${measureSuffix}`;

        let settingsSuffix = this.translateFromSettings(context, "suffix");
        if (settingsSuffix)
            return ` ${settingsSuffix}`;

        return "";
    }

    dateCategoryFormatter(label: any) {
        let rollingGrouping = this.context?.settings?.measures?.find((x: any) => x.comparisonType === 3);
        let dateTimeDimension = this.context?.dimensions?.find((x: any) => x.dataType == 3);

        if (label && dateTimeDimension) {
            let labelWithoutBracets = label.replace('{', '').replace('}', '');
            let bin = rollingGrouping?.transformationSettings?.groupedBin ?? dateTimeDimension.bin;
            let formatted = `{${labelWithoutBracets}.formatDate('${this.getDateTimeFormat(bin)}')}`;
            if (bin === 6) {
                return `Q${formatted}`;
            }
            return `${formatted}`;
        }
        return `{${label}}`;
    }

    getDateTimeFormat(bin: number) {
        switch (bin) {
            case 1:
                return 'dd.MMM yyyy H:mm';
            case 2:
                return 'dd.MMM yyyy H:00';
            case 3:
                return 'dd.MMM yyyy';
            case 4:
                return 'w, yyyy';
            case 5:
                return 'MMM yyyy';
            case 6:
                return 'q, yyyy';
            default:
                return 'yyyy';
        }
    }

    setFixedBigNumberValueAxis(valueAxis: any, measureAppearance: any, settings: any) {
        let fixedBigNumberFormat = this.getMeasureFixedBigNumberFormat(measureAppearance, settings);
        let numberFormat = this.getMeasureNumberFormat(measureAppearance, settings);


        if (this.isFixedBigNumberFormat(fixedBigNumberFormat, numberFormat)) {
            const decimalPart = numberFormat.split('.')[1]?.split('a')[0];
            const precision: number = decimalPart ? decimalPart.length : 0;
            let language = this.getActiveLanguage() ?? this.getLanguageAbbreviation(this.getActiveLanguage());

            valueAxis.renderer.labels.template.adapter.add("text", (label: any, target: any) => {
                if (target?.dataItem?.value) {
                    let number = target.dataItem.value / fixedBigNumberFormat;
                    let formatter = new Intl.NumberFormat(language, {
                        minimumFractionDigits: precision,
                        maximumFractionDigits: precision,
                    });

                    let bigNumberPrefix = this.getBigNumberPrefix(measureAppearance, settings);
                    return formatter.format(number) + " " + bigNumberPrefix;
                }
                return label;
            });
        }
    }

    setLabelVisibility(valueAxis: any, settings: any) {
        valueAxis.renderer.labels.template.disabled = settings.hideAxisLabels ?? false;
    }


    setFixedBigNumberLabel(labelBullet: any, measureAppearance: any, settings: any) {
        let fixedBigNumberFormat = this.getMeasureFixedBigNumberFormat(measureAppearance, settings);
        let numberFormat = this.getMeasureNumberFormat(measureAppearance, settings);

        if (this.isFixedBigNumberFormat(fixedBigNumberFormat, numberFormat)) {
            const decimalPart: string | undefined = numberFormat.split('.')[1]?.split('a')[0];
            const precision: number = decimalPart ? decimalPart.length : 0;
            let language = this.getActiveLanguage() ?? this.getLanguageAbbreviation(this.getActiveLanguage());
            let prefix = this.getPrefix(this.context, measureAppearance, settings);
            let suffix = this.getSuffix(this.context, measureAppearance, settings);

            labelBullet.adapter.add("text", (label: any, target: any) => {
                let number = (target.dataItem.valueX ?? target.dataItem.valueY) / fixedBigNumberFormat;
                let formatter = new Intl.NumberFormat(language, {
                    minimumFractionDigits: precision,
                    maximumFractionDigits: precision,
                });
                let bigNumberPrefix = this.getBigNumberPrefix(measureAppearance, settings);
                if (target.dataItem && (target.dataItem.valueX ?? target.dataItem.valueY))
                    return prefix + formatter.format(number) + " " + bigNumberPrefix + suffix;

                return label;
            });
        }
    }

    setFixedBigNumberTooltip(series: any, measureAppearance: any, settings: any) {
        let fixedBigNumberFormat = this.getMeasureFixedBigNumberFormat(measureAppearance, settings);
        let numberFormat = this.getMeasureNumberFormat(measureAppearance, settings);

        if (this.isFixedBigNumberFormat(fixedBigNumberFormat, numberFormat)) {
            const decimalPart: string | undefined = numberFormat.split('.')[1]?.split('a')[0];
            const precision: number = decimalPart ? decimalPart.length : 0;
            let language = this.getActiveLanguage() ?? this.getLanguageAbbreviation(this.getActiveLanguage());
            let prefix = this.getPrefix(this.context, measureAppearance, settings);
            let suffix = this.getSuffix(this.context, measureAppearance, settings);

            series.tooltip.label.adapter.add("text", (label: any, target: any) => {
                let number = (target.dataItem.valueX ?? target.dataItem.valueY) / fixedBigNumberFormat;
                let formatter = new Intl.NumberFormat(language, {
                    minimumFractionDigits: precision,
                    maximumFractionDigits: precision,
                });

                let dataItem = target.dataItem;
                let bigNumberPrefix = this.getBigNumberPrefix(measureAppearance, settings);

                if (dataItem && (dataItem.valueX ?? dataItem.valueY))
                    return series.name + ": " + prefix + formatter.format(number) + " " + bigNumberPrefix + suffix;
                return label;
            });
        }
    }

    isFixedBigNumberFormat(fixedBigNumberFormat: any, numberFormat: any) {
        return fixedBigNumberFormat != -1 && numberFormat.includes("a");
    }

    getBigNumberPrefix(measureAppearance: any, settings: any) {
        let bigNumberPrefixes = this.getSiFormat();
        if ((measureAppearance?.numberFormat !== "not_set" && measureAppearance?.numberStandard === "Default") || (settings?.numberFormat !== "" && settings?.numberStandard === "Default"))
            bigNumberPrefixes = this.getBigNumberPrefixesLocale();

        switch (this.getMeasureFixedBigNumberFormat(measureAppearance, settings)) {
            case 1e+6:
                return bigNumberPrefixes.million;
            case 1e+9:
                return bigNumberPrefixes.billion;
            default:
                return bigNumberPrefixes.thousand;
        }
    }

    setCornerRadius(useCornerRadius: boolean, column: any) {
        if (useCornerRadius) {
            column.adapter.add("cornerRadiusTopLeft", (radius: any, target: any) => (target.dataItem && (target.dataItem.valueY < 0)) ? 0 : 4);
            column.adapter.add("cornerRadiusTopRight", (radius: any, target: any) => (target.dataItem && (target.dataItem.valueY < 0)) ? 0 : 4);
            column.adapter.add("cornerRadiusBottomLeft", (radius: any, target: any) => (target.dataItem && (target.dataItem.valueY > 0)) ? 0 : 4);
            column.adapter.add("cornerRadiusBottomRight", (radius: any, target: any) => (target.dataItem && (target.dataItem.valueY > 0)) ? 0 : 4);

            //series.columns.template.column.cornerRadius(4, 4, 0, 0);
        }
    }
}
