import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as sliceGrouper from '@amcharts/amcharts4/plugins/sliceGrouper';
import { Amcharts } from './Amcharts';

export class AmchartsPiechart extends Amcharts {

    constructor() {
        super();
    }

    createByRef(ref: any, context: any): any {
        this.applyTheme(context, () => {
            this.create(ref, am4charts.PieChart, context);

            context.settings = this.getSettings(context);
            this.chart.innerRadius = am4core.percent(context.settings.pieRadius || 0);
            this.chart.radius = am4core.percent(context.settings.radius || 80);

            for (let i = 0; i < context.measures.length; i++) {
                let measure = context.measures[i];
                for (let j = 0; j < context.dimensions.length; j++) {
                    let dimension = context.dimensions[j];
                    let measureResultName = measure.resultName || "value" + i;
                    let dimensionResultName = dimension.resultName || "key" + j;

                    let series = this.createSeries(measureResultName, dimensionResultName);
                    this.setSeriesSettings(series, context, measureResultName);
                    this.onClickSliceHandler(this.chart, series, context);
                }
            }
        });
        return this;
    }

    //recreate(ref: any, context: any) {
    //    this.dispose();
    //    this.createByRef(ref, context);
    //}

    createSerieses(measures: any, categoryFieldName: any, _context: any) {
        let i = this.chart.series.length;
        let me = this;
        measures.forEach(function (measure: { resultName: string; }) {
            let valueFieldName = measure.resultName || "value" + i;
            me.createSeries(valueFieldName, categoryFieldName);
            i++;
        });
    }

    createSeries(valueFieldName: any, categoryFieldName: any) {
        let series = this.chart.series.push(new am4charts.PieSeries());

        series.dataFields.value = valueFieldName;
        series.dataFields.category = categoryFieldName;
        series.slices.template.stroke = am4core.color("white");
        series.slices.template.strokeWidth = 2;
        series.slices.template.strokeOpacity = 1;
        series.slices.template.cornerRadius = 2;
        series.slices.template.tooltipText = this.context.settings.disableTooltips  ? "" : "{category}: [bold]{value}[/]";
        
        //series.slices.template.tooltipText = "{category}: {value.percent.formatNumber('#,###.0')}% ({value})";

        let pieRadius = this.context.settings.radius || 80;
        let labelMaxWidth = this.context.settings.pieLabelPosition == 'surface' && pieRadius >= 80 ? 100 : undefined;
        series.labels.template.maxWidth = this.context.settings.labelMaxWidth || labelMaxWidth || 250;
        series.labels.template.text = "{category}: {value.percent.formatNumber()}%";
        series.legendSettings.valueText = "{value.percent.formatNumber()}%";
        series.labels.template.wrap = true;

        //series.labels.template.bent = true;
        //series.labels.template.radius = -20;
        //series.alignLabels = false;

        return series;
    }

    setSeriesSettings(series: any, context: any, valueFieldName: any) {
      
        let settings = this.getSettings(context);
        const measureAppearance = settings.measures.find((f: any) => f.name == valueFieldName);

        let prefix = this.getPrefix(context, measureAppearance, settings);
        let suffix = this.getSuffix(context, measureAppearance, settings);

        series.slices.template.tooltipText = this.context.settings.disableTooltips ? "" : `{category}: [bold]${prefix}{value}${suffix}[/]`;

        if (settings.pieLabelPosition === 'outside') {
            series.ticks.template.disabled = false;
            series.labels.template.disabled = false;
            series.labels.template.radius = am4core.percent(5);
        }
        else if (settings.pieLabelPosition === 'surface') {
            series.ticks.template.disabled = false;
            series.labels.template.disabled = false;
            series.alignLabels = false;
        }
        else if (settings.pieLabelPosition === 'none') {
            series.ticks.template.disabled = true;
            series.labels.template.disabled = true;
        }
        else {
            series.labels.template.disabled = false;
            series.labels.template.radius = am4core.percent(-30);
        }

        series.labels.template.fill = am4core.color(settings.pieLabelColor);
        series.labels.template.fontSize = settings.pieLabelFontSize + 'px';
        series.labels.template.fontWeight = settings.pieLabelFontWeight;
        series.labels.template.paddingTop = 0;
        series.labels.template.paddingBottom = 0;

        if (settings.enableSliceGroup) {

            if (series.plugins.values.length == 0)
                series.plugins.push(new sliceGrouper.SliceGrouper());
            
            series.plugins.values[0].threshold = settings.sliceGroupValue;
            series.plugins.values[0].groupName = settings.sliceGroupTitle;
            series.plugins.values[0].clickBehavior = settings.sliceGroupBehavior;
        } 
    }

    removeSeries(index: any) {
        this.chart.series.removeIndex(index);
    }

    removeSerieses() {
        while (this.chart.series.length) {
            this.chart.series.removeIndex(0);
        }
    }

    createLegend(): any {
        let legend = super.createLegend();

        legend.itemContainers.template.events.on("hit", (ev: any) => {
            let slice = ev.target.dataItem.dataContext.slice;
            slice.isActive = !slice.isActive;
        });
        return legend;
    }

    updateSettings(context: any) {
        super.updateSettings(context);

        let settings = this.getSettings(context);

        if (settings) {
            this.chart.innerRadius = am4core.percent(settings.pieRadius || 0);
            this.chart.series.each((s: any) => this.setSeriesSettings(s, context, s.dataFields.value));
            if (this.chart.data && this.chart.data.length > 0)
                this.setLegend(settings);            
        }
    }

    setData(context: any) {
        if (context.data) {            
            this.chart.data = context.data;
            this.setSeriesColors(context);
            this.setLegend(context.settings);            
        }
    }

    markActiveByValue(values: any[]) {
        setTimeout(() => {
            this.chart.series.each((s: any) => s.slices.each((ss: any, _i: number) => {
                values.forEach((v: any) => {
                    if (v == ss.dataItem.category) {
                        ss.isActive = true;
                    }
                })
            }));
        }, 100);
    }

    getSettings(context: any) {
        context.settings.pieRadius = context.settings.pieRadius ?? context.settings.donutRadius;
        context.settings.pieLabelFontSize = !context.settings.pieLabelFontSize ? context.settings.labelFontSize : context.settings.pieLabelFontSize;
        context.settings.pieLabelFontWeight = !context.settings.pieLabelFontWeight ? context.settings.labelFontWeight : context.settings.pieLabelFontWeight;
        context.settings.pieLabelColor = !context.settings.pieLabelColor ? context.settings.labelColor : context.settings.pieLabelColor;
        context.settings.pieLabelPosition = !context.settings.pieLabelPosition ? context.settings.labelPosition : context.settings.pieLabelPosition;

        return context.settings;
    }

    setSeriesColors(context: any) {
        let me = this;
        this.chart.series.each((series:any, _index:any) =>{
        series.slices.template.adapter.apply("fill", (fill:any, target:any) => {
            return target.dataItem ? me.getThemeColors(context)[target.dataItem.index] : fill;
        });
    });
}
}