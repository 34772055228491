import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { Amcharts } from './Amcharts';

export class AmchartsColumnchart extends Amcharts {

    constructor() {
        super();

        this.whenReady((_ev: any) => {
            this.chart.events.on("datavalidated", (_e: any) => {
                this.chart.yAxes.each((valueAxis: any) => {
                    valueAxis.zoomable = false;
                    valueAxis.toggleZoomOutButton = false; 
                           });
                     });
                });
    }

    createByRef(ref: any, context: any) : any {
        let settings = context == null ? null : context.settings;

        this.applyTheme(context, () => {
            this.create(ref, am4charts.XYChart, context);
            this.removeSerieses();

            this.createAxis(context);            

            this.updateValueAxes(context);

            for (let j = 0; j < context.dimensions.length; j++) {
                let dimension = context.dimensions[j];
                let dimensionResultName = dimension.resultName || "key" + j;
                this.createCategoryAxis(dimensionResultName, context);
            }
            this.updateCategoryAxis(context);

            this.createSerieses(context);
            this.updateMeasures(context);
            this.updateDimensions(context);

            if (this.shouldUseCursor(context)) {
                let cursor = new am4charts.XYCursor();
                cursor.behavior = "panXY";
                this.chart.cursor = cursor;
            }
        });
        return this;
    }

    //recreate(ref: any, context: any) {
    //    this.dispose();
    //    this.createByRef(ref, context);
    //}

    createAxis(context: any) {
        let doGlobalAxisExist = false;
        let doGlobalDeltaAxisExist = false;
        let measures = this.context.isCluster ? context.basicMeasures : context.measures;

        measures.forEach((measure: any, i: any) => {
            let measureResultName = measure.resultName || "value" + i;
            let measureAppearance = context.settings.measures?.length
                ? context.settings.measures?.find((m: any) => m.name === measureResultName)
                : {};

            let measureContext = {
                measure,
                measureAppearance,
                settings: context.settings,
            }

            let isDeltaPercent = measure?.valueKind == 2;

            if (isDeltaPercent) {
                if (measureAppearance?.hasOwnAxis || !doGlobalDeltaAxisExist) {
                    this.createDeltaValueAxis(measureContext);
                    doGlobalDeltaAxisExist = true;
                }
            }
            else if (measureAppearance?.hasOwnAxis || !doGlobalAxisExist)
            {
                this.createValueAxis(measureContext);
                doGlobalAxisExist = true;
            }

        });
    }

    createValueAxis(context: any) {
        let measure = context?.measure;
        let measureAppearance = context?.measureAppearance;
        let settings = context?.settings;

        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.dataFields.name = measure?.name ?? '';
        valueAxis.dataFields.resultName = measure?.resultName ?? "value0";
        valueAxis.numberFormatter = new am4core.NumberFormatter();
        valueAxis.max = settings.axisMaxValue;
        valueAxis.min = settings.axisMinValue;
        if (context.settings.axisGridDistance != null)
            valueAxis.renderer.minGridDistance = context.settings.axisGridDistance;

        if (context.settings?.stacked && context.settings?.percentage) 
            valueAxis = this.setPercentageAxis(valueAxis);
         else {
            valueAxis.numberFormatter.numberFormat = this.getMeasureNumberFormat(measureAppearance, settings);
            valueAxis.numberFormatter.bigNumberPrefixes = this.getBigNumberPrefixes(measureAppearance, settings);
        }

        valueAxis.showOnInit = true;
        valueAxis.keepSelection = true;

        this.showValueAxisTooltip(valueAxis, context.settings.showValueAxisTooltip);
        this.setFixedBigNumberValueAxis(valueAxis, measureAppearance, settings);
        this.setLabelVisibility(valueAxis, settings);

        return valueAxis;
    }

    createDeltaValueAxis(context: any) {
        let valueAxis = this.createValueAxis(context);

        valueAxis.dataFields.deltaName = "_delta_" + context.measure.resultName;
        valueAxis.dataFields.hasDelta = true;

        this.showValueAxisTooltip(valueAxis, context.settings.showValueAxisTooltip);

        if (valueAxis.numberFormatter.numberFormat.includes('a'))
            valueAxis.numberFormatter.numberFormat = "#,###.";        

        return valueAxis;
    }

    createCategoryAxis(categoryFieldName: any, context: any) {
        if (this.chart.xAxes.length) {
            this.updateCategoryAxis(context);
            return this.chart.xAxes.getIndex(0);
        }
        else {
            let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = categoryFieldName;
            categoryAxis.tooltip.label.maxWidth = 200;
            categoryAxis.tooltip.label.wrap = true;
            categoryAxis.cursorTooltipEnabled = false;
            categoryAxis.renderer.labels.template.adapter.add("text", (label: any, target: any) => {
                if (label)
                    label = label?.replace('{', '')?.replace('}', '');

                return this.dateCategoryFormatter(label);
            });

            this.updateCategoryAxisSettings(categoryAxis, context);
            return categoryAxis;
        }
    }

    createSerieses(context: any) {
        let i = 0;
        let j = 0;
        let me = this;
        let measures = context.measures;
        let dimensions = context.dimensions;

        measures.forEach((measure: any) => {
            let valueFieldName = measure.resultName || "value" + i++;
            let isDelta = measure?.valueKind != 0;

            dimensions.forEach((dimension: any, x: number) => {
                let dimensionResultName = dimension.resultName || "key" + j++;
                me.createSeries(valueFieldName, dimensionResultName, context, isDelta, i);
            });
        });
    }

    createSeries(valueFieldName: any, categoryFieldName: any, context: any, isDelta: any, measureIndex: any) {
        const definedFieldName = isDelta ? "_delta_" + valueFieldName : valueFieldName;
        const { settings, measures, dimensions, isCluster } = context;
        const seriesType = this.getSeriesType(valueFieldName, settings);
        const measure = measures.find((f: any) => f.resultName == valueFieldName);
        const measureAppearance = isCluster ? settings.measures[0] : settings.measures.find((f: any) => f.name == valueFieldName);
        const bigPrefixes = this.getBigNumberPrefixes(measureAppearance, settings);
        const basicMeasures = context.basicMeasures ?? measures;

        let numberFormat = this.getMeasureNumberFormat(measureAppearance, settings);
        numberFormat += this.percentSignIfNeeded(numberFormat, measure, isDelta, basicMeasures, isCluster, measureIndex);

        let series: any = null;
        series = this.createSeriesByType(seriesType, measure, numberFormat, bigPrefixes);

        const dimension = dimensions.find((d: any) => d.resultName == categoryFieldName);
        //const dimensionIsDateTime = dimension?.dataType == 3;
        const category = /*dimensionIsDateTime ? "dateX" :*/ "categoryX";
        const valueFormat = settings.stacked && settings.percentage ? "{valueY.totalPercent.formatNumber('" + numberFormat.replace(/%/g, "") + "')}%" : "{valueY.formatNumber('" + numberFormat + "')}";

        if (series instanceof am4charts.ColumnSeries)
            this.setSeriesColumnProperties(series, settings, category, valueFormat, measureAppearance, context, numberFormat);
        else
            this.setSeriesLineProperties(series, settings, category, valueFormat, measureAppearance, context);

        this.setFixedBigNumberTooltip(series, measureAppearance, settings);
        //if (dimensionIsDateTime)
        //    series.dataFields.dateX = categoryFieldName;
        //else
            series.dataFields.categoryX = categoryFieldName;

        series.dataFields.valueY = definedFieldName;

        this.appendThreshold(context, series, valueFieldName);
        this.updateSeries(context, valueFieldName);

        let hasOwnAxis = measureAppearance?.hasOwnAxis;
        series.yAxis = this.getAppropriateYAxis(definedFieldName, context, hasOwnAxis, isDelta);

        this.setSeriesSettings(series, settings, valueFormat, context);
       
        return series;
    }

    createSeriesByType(seriesType: any, measure: any, numberFormat: any, bigPrefixes: any) {
        let series = !seriesType || seriesType == 'bar' ? this.chart.series.push(new am4charts.ColumnSeries()) : this.chart.series.push(new am4charts.LineSeries());
        series.name = this.getSeriesName(measure);
        series.numberFormatter = new am4core.NumberFormatter();
        series.numberFormatter.numberFormat = numberFormat;
        series.numberFormatter.bigNumberPrefixes = bigPrefixes;

        return series;
    }

    private setSeriesColumnProperties(series: any, settings: any, category: any, valueFormat: any, measureAppearance: any, context: any, numberFormat: any) {
        let prefix = this.getPrefix(context, measureAppearance, settings);
        let suffix = this.getSuffix(context, measureAppearance, settings);
        let template = series.columns.template;
        const isStacked = settings?.stacked ?? false;
        const useCornerRadius = !isStacked;

        template.fillOpacity = .8;
        template.strokeWidth = 1;
        template.strokeOpacity = 0.8;

        this.setCornerRadius(useCornerRadius, template.column);
        //if (useCornerRadius) {
        //    template.column.adapter.add("cornerRadiusTopLeft", (radius: any, target: any) => (target.dataItem && (target.dataItem.valueY < 0)) ? 0 : 4);
        //    template.column.adapter.add("cornerRadiusTopRight", (radius: any, target: any) => (target.dataItem && (target.dataItem.valueY < 0)) ? 0 : 4);
        //    template.column.adapter.add("cornerRadiusBottomLeft", (radius: any, target: any) => (target.dataItem && (target.dataItem.valueY > 0)) ? 0 : 4);
        //    template.column.adapter.add("cornerRadiusBottomRight", (radius: any, target: any) => (target.dataItem && (target.dataItem.valueY > 0)) ? 0 : 4);

        //    //template.column.cornerRadius(4, 4, 0, 0);
        //}

        series.stacked = isStacked;
        let tooltipText = settings.isCluster
            ? `${this.dateCategoryFormatter(category)} - {name}: [bold]${prefix}${valueFormat}${suffix}[/]`
            : `${this.dateCategoryFormatter(category)}: [bold]${prefix}${valueFormat}${suffix}[/]`;

        if (settings.stacked && settings.percentage && settings.showMultiTooltips)
            series.tooltipText = tooltipText;
        else
            template.tooltipText = tooltipText;

        if (!context.settings.showMultiTooltips)
            series.cursorTooltipEnabled = false; 

        this.appendColumnActiveState(series);
        this.onClickColumnHandler(this.chart, series, context);
    }

    setSeriesLineProperties(series: any, settings: any, category: any, valueFormat: any, measureAppearance: any, context: any) {
        let prefix = this.getPrefix(context, measureAppearance, settings);
        let suffix = this.getSuffix(context, measureAppearance, settings);
        let template = series;
        template.minBulletDistance = 0;
        template.strokeWidth = 3;
        template.strokeOpacity = 0.8;

        if (measureAppearance)
            series.fillOpacity = measureAppearance.fillArea ? 0.3 : undefined;

        let tooltipText = `${this.dateCategoryFormatter(category)}: [bold]${prefix}${valueFormat}${suffix}[/]`; 

        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 2;
        bullet.circle.radius = 4;
        bullet.circle.fill = am4core.color("#fff");
        bullet.tooltipText = tooltipText;
        let bullethover = bullet.states.create("hover");
        bullethover.properties.scale = 1.3;

        if (settings.stacked && settings.percentage && settings.showMultiTooltips)
            series.tooltipText = tooltipText;
        else
            template.tooltipText = tooltipText;

        template.tooltip.pointerOrientation = "vertical";
        template.tooltip.label.textAlign = "middle";
        template.tooltip.label.textValign = "middle";
    }

    setSeriesSettings(series: any, settings: any, valueFormat: any, context: any) {
        if (settings.stacked && settings.percentage) {
            series.calculatePercent = true;
            series.legendSettings.itemValueText = settings.showLegendPercent ? valueFormat : "";
            series.dataFields.valueYShow = "totalPercent";
        }

        let firstDimension = context.settings?.dimensions == undefined ? null : settings?.dimensions[0];
        if (firstDimension?.useGradient && context.settings?.dimensions?.length == 1)
            series.columns.template.adapter.add("fill", (fill: any, target: any) => target.dataItem ? this.getThemeColors(context)[target.dataItem.index] : fill);
    }

    updateSeries(context: any, valueFieldName?: any) {
        let settings = context.settings;
        this.chart.series.each((series: any) => {
            if (settings.measures != null) {
                let measureAppearance = this.context.isCluster ? settings.measures[0] : settings.measures.find((f: any) => f.name == valueFieldName);
                this.setBullets(series, settings, measureAppearance);
            }
        });
        Amcharts.prototype.updateSeries.call(this, context)
    }

    setBullets(series: any, settings: any, measureAppearance: any) {
        const bullet = series.bullets.push(new am4charts.LabelBullet());

        if (this.isNeedToShowLabels(this.showLabelsFromApp, this.context.showLabels) || this.context.showLabels)
        {
            let label = bullet.label;

            if (!settings.labelBulletPosition || settings.labelBulletPosition === "None") {
                label.disabled = true;
                return;
            }

            let prefix = this.getPrefix(this.context, measureAppearance, settings);
            let suffix = this.getSuffix(this.context, measureAppearance, settings);
            let numberFormat = this.getMeasureNumberFormat(measureAppearance, settings);

            const value = settings.stacked && settings.percentage
                ? "{valueY.totalPercent.formatNumber('" + numberFormat.replace(/%/g, "") + "')}%"
                : `${prefix}{valueY}${suffix}`;

            label.disabled = false;
            label.text = value;
            label.fill = am4core.color(settings.labelBulletColor || 'black');
            label.fontWeight = 'bold';
            label.hideOversized = false;
            label.truncate = false;

            this.addBulletAdapters(label, settings, measureAppearance);
        }
        
    }

    addBulletAdapters(label: any, settings: any, measureAppearance: any) {
        label.adapter.add("verticalCenter", (center: any, target: any) => {
            if (!target.dataItem) return center;

            const position = settings.labelBulletPosition;
            const value = target.dataItem.values.valueY.value;
            const isInside = position === 'inside';
            const moreZero = isInside ? "top" : "bottom";
            const lessZero = isInside ? "bottom" : "top";

            return value > 0 ? moreZero : lessZero;
        });

        label.adapter.add("dy", (dy: any, target: any) => {
            if (!target.dataItem) return dy;

            const coef = 10;
            const position = settings.labelBulletPosition;
            const value = target.dataItem.values.valueY.value;
            const isInside = position === 'inside';
            const moreZero = isInside ? coef : -coef;
            const lessZero = isInside ? -coef : coef;

            return value > 0 ? moreZero : lessZero;
        });

        this.setFixedBigNumberLabel(label, measureAppearance, settings);         
    }

    getSeriesType(measureName: any, settings: any) {
        if (settings && settings.measures) {
            let measureAppearance = this.context.isCluster ? settings.measures.find((m: any) => this.context.basicMeasures.find((x: any) => x.resultName == m.name)) : settings.measures.find((m: any) => measureName.includes(m.name));
                
            if (measureAppearance)
                return measureAppearance.seriesType;            
        }
    }

    updateSettings(context: any) {
        super.updateSettings(context);

        let settings = context.settings;

        if (settings) {
            this.updateCategoryAxis(context);
            this.updateValueAxes(context);
            this.removeSerieses();
            this.createSerieses(context);
            this.updateDimensions(context);
            this.updateMeasures(context);

            if (this.chart.data && this.chart.data.length > 0) 
                this.setLegend(settings);            
        }
    }

    updateCategoryAxisSettings(categoryAxis: any, context: any) {
        let isCluster = context.isCluster;
        categoryAxis.renderer.cellStartLocation = isCluster ? context.cellStartLocation : 0;
        categoryAxis.renderer.cellEndLocation = isCluster ? context.cellEndLocation : 1;

        let dimension = context.settings?.dimensions?.length ? context.settings.dimensions?.filter((d: any) => d.name != null && d.categoryLabelRotation)[0] : {};
        categoryAxis.renderer.minGridDistance = dimension?.categoryLabelDistance ?? 0;

        let label = categoryAxis.renderer.labels.template;
        label.rotation = dimension?.categoryLabelRotation;
        label.tooltipText = `${this.dateCategoryFormatter(`category`)}`;
        label.truncate = true;
        label.maxWidth = 130;
        let labelCenter = this.getLabelCenter(label.rotation);
        label.horizontalCenter = labelCenter.horizontalCenter;
        label.verticalCenter = labelCenter.verticalCenter;

        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.title.fontWeight = "bold";

        categoryAxis.numberFormatter = new am4core.NumberFormatter();
        categoryAxis.numberFormatter.numberFormat = this.getDimensionNumberFormat(dimension);
    }

    updateCategoryAxis(context: any) {
        if (context.settings == null) return;

        let categoryAxis = this.chart.xAxes.getIndex(0);
        if (categoryAxis)
            this.updateCategoryAxisSettings(categoryAxis, context);        
    }

    removeCategoryAxis(index: any) {
        this.chart.xAxes.removeIndex(index);
    }

    updateValueAxes(context: any) {

        for (let i = 0; i < context.measures.length; i++) {
            let measure = context.measures[i];
            let measureResultName = measure.resultName || "value" + i;
            let measureAppearance = context.settings.measures?.length
                ? context.settings.measures?.find((m: any) => m.name === measure.resultName)
                : {};
            let isOpposite = measureAppearance?.hasOwnAxis ? measureAppearance.axisPosition === "opposite" : false;

            let yAxis = this.chart.yAxes?.values?.find((axis: any) => axis.dataFields.resultName === measureResultName);

            if (yAxis !== undefined && yAxis !== null)
                yAxis.renderer.opposite = isOpposite;            

            this.updateValueAxis(context, yAxis, measure);
        }

        this.baseSyncAxesByZero(context, this.chart.yAxes);
    }

    updateValueAxis(context: any, valueAxis: any, measure: any) {
        if (context?.settings == null) return;

        let firstAxis = this.chart.yAxes?.values?.[0];
        let measureAppearance = context.settings.measures?.find((m: any) => m.name === measure.resultName);

        if (valueAxis) {
            valueAxis.title.fontWeight = "bold";
            valueAxis.title.text = this.translateFromMeasure(context, measureAppearance, "axisTitle");
            valueAxis.numberFormatter = new am4core.NumberFormatter();
            valueAxis.max = context.settings.axisMaxValue;
            valueAxis.min = context.settings.axisMinValue;
            if (context.settings.axisGridDistance != null)
                valueAxis.renderer.minGridDistance = context.settings.axisGridDistance;

            if (context.settings?.stacked && context.settings?.percentage)
                valueAxis = this.setPercentageAxis(valueAxis);
            else {
                valueAxis.numberFormatter.bigNumberPrefixes = this.getBigNumberPrefixes(measureAppearance, context.settings);
                valueAxis.numberFormatter.numberFormat = this.getMeasureNumberFormat(measureAppearance, context.settings);
            }  
        }
        if (valueAxis && valueAxis === firstAxis)
            valueAxis.title.text = this.translateFromMeasure(context, measureAppearance, "axisTitle") || this.translateFromSettings(context, "valueAxisTitle");
    }

    updateDimensions(context: any) {
        let settings = context.settings || {};
        if (!settings?.dimensions) return;

        for (let dimension of settings.dimensions) {
            for (let j = 0; j < this.chart.xAxes.length; j++) {
                let xAxis = this.chart.xAxes.getIndex(j);
                if (xAxis.dataFields.category == dimension.name) {
                    xAxis.title.text = this.traslateFromDimension(context, dimension, "title");
                }
            }
        }
    }

    updateMeasures(context: any) {
        let settings = context.settings || {};
        if (!settings?.measures) return;

        const measureAppearances = settings.measures.filter((m: any) => m.title !== '');
        if (measureAppearances.length) {
            for (const measureAppearance of measureAppearances) {
                for (let j = 0; j < this.chart.series.length; j++) {
                    const serie = this.chart.series.getIndex(j);
                    if(serie.dataFields.valueY.includes(measureAppearance.name)) {
                        serie.name = this.translateFromMeasure(context, measureAppearance, "title");
                    }
                }
            }
        }
    }

    setData(context: any) {
        if (context.data) {
            this.chart.colors.reset();
            this.chart.data = context.data;
            if (context.data.length) {
                this.setLegend(context.settings);
                this.updateValueAxes(context);
                this.syncValueAxes(context);
            }
        }
    }

    syncValueAxes(context: any) {
        const values = this.chart.yAxes.values;
        if (values?.length > 0) {
            const mainAxis = this.getMainValueAxis(context);
            this.baseSyncValueAxes(this.chart.yAxes, mainAxis);
        }
    }

    getMainValueAxis(context: any) : any {
        const measureAppearancesWithOwnAxes = context?.settings?.measures?.filter((m: any) => m.hasOwnAxis);
        const measureAppearancesWithDeltaAxes = context?.measures?.filter((m: any) => m?.valueKind != 0);

        const yAxesWithMultipleGeneral = this.getMarkedMultipleAxes(this.chart.yAxes, measureAppearancesWithOwnAxes,
            { measuresSettingsWithDeltaAxes: measureAppearancesWithDeltaAxes, mode: "general" });

        const mainAxis = yAxesWithMultipleGeneral.find((axis: any) => (axis !== null && axis !== undefined));
        return mainAxis ?? null;
    }


    markActiveByValue(values: any[]) {
        setTimeout(() => {
            this.chart.series.each((s: any) => s.columns.each((c: any, _i: number) => {
                values.forEach((v: any) => {
                    if (v == c.dataItem.categoryX)
                        c.isActive = true;                    
                })
            }));
        }, 100);
    }

    getAppropriateYAxis(valueFieldName: any, context: any, hasOwnAxis: boolean, isDeltaAxis: boolean) {
        if (hasOwnAxis || isDeltaAxis) 
            return this.getActiveAxis(this.chart.yAxes, valueFieldName);
          else {
            const measureAppearancesWithOwnAxes = context?.settings?.measures?.filter((m: any) => m.hasOwnAxis);
            const measureAppearancesWithDeltaAxes = context?.measures?.filter((m: any) => m?.valueKind != 0);

            const yAxesMarkedMultipleSpecial = this.getMarkedMultipleAxes(this.chart.yAxes, measureAppearancesWithOwnAxes,
                { measuresSettingsWithDeltaAxes: measureAppearancesWithDeltaAxes, mode: "special" });

            const appropriateAxis = yAxesMarkedMultipleSpecial.find((axis: any) => (axis !== null && axis !== undefined));
            return appropriateAxis ?? this.chart.yAxes.values[0];
        } 
    }

    removeValueAxisByResultName(resultName: string) {
        let yAxis = this.chart.yAxes?.values?.find((axis: any) => axis.dataFields.resultName === resultName);
        if (yAxis !== undefined) {
            let index = this.chart.yAxes.indexOf(yAxis);
            this.chart.yAxes.removeIndex(index);
        }
    }

}