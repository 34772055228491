import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { Amcharts } from './Amcharts';

export class AmchartsBarchart extends Amcharts {

    constructor() {
        super();

        this.whenReady((_ev: any) => {
            this.chart.events.on("datavalidated", (_e: any) => {
                this.chart.xAxes.each((valueAxis: any) => {
                    valueAxis.zoomable = false;
                    valueAxis.toggleZoomOutButton = false;
                });
            });
        });
    }

    createByRef(ref: any, context: any): any {
        let settings = context.settings || {};

        this.applyTheme(context, () => {
            this.create(ref, am4charts.XYChart, context);
            this.createAxis(context);

            this.updateValueAxes(context);

            if (context.dimensions.length) {
                let dimension = context.dimensions[0];
                let dimensionResultName = dimension.resultName || "key0";
                let categoryAxis = this.createCategoryAxis(dimensionResultName, context);
                categoryAxis.renderer.inversed = true;
            }
            this.createSerieses(context);
            this.updateMeasures(context);

            if (this.shouldUseCursor(context)) {
                let cursor = new am4charts.XYCursor();
                cursor.behavior = "panXY";
                this.chart.cursor = cursor;
            }
        });

        return this;
    }

    //recreate(ref: any, context: any) {
    //    this.dispose();
    //    this.createByRef(ref, context);
    //}

    createAxis(context: any) {
        let isGlobalAxisExist = false;
        let isGlobalDeltaAxisExist = false;
        let measures = this.context.isCluster ? context.basicMeasures : context.measures;

        measures.forEach((measure: any, i: any) => {
            const measureResultName = measure.resultName || `value${i}`;
            const measureAppearance = context.settings.measures?.find((m: any) => m.name === measureResultName) || {};

            const measureContext = {
                measure,
                measureAppearance,
                settings: context.settings,
                culture: context.culture
            };

            let isDeltaPercent = measure?.valueKind == 2;

            if (isDeltaPercent) {
                if (measureAppearance?.hasOwnAxis || !isGlobalDeltaAxisExist) {
                    this.createDeltaValueAxis(measureContext);
                    isGlobalDeltaAxisExist = true;
                }
            }
            else if (measureAppearance.hasOwnAxis || !isGlobalAxisExist) {
                this.createValueAxis(measureContext);
                isGlobalAxisExist = true;
            }
        });
    }

    createDeltaValueAxis(context: any) {
        let valueAxis = this.createValueAxis(context);

        valueAxis.dataFields.deltaName = "_delta_" + context.measure.resultName;
        valueAxis.dataFields.hasDelta = true;

        if (valueAxis.numberFormatter.numberFormat.includes('a'))
            valueAxis.numberFormatter.numberFormat = "#,###.";

        this.showValueAxisTooltip(valueAxis, context.settings.showValueAxisTooltip);

        return valueAxis;
    }

    createValueAxis(context: any) {
        let measure = context?.measure;
        let measureAppearance = context?.measureAppearance;
        let settings = context?.settings;

        let valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = this.translateFromSettings(context, "valueAxisTitle");
        valueAxis.dataFields.name = measure?.name;
        valueAxis.dataFields.resultName = measure?.resultName;

        valueAxis.numberFormatter = new am4core.NumberFormatter();
        valueAxis.max = settings.axisMaxValue;
        valueAxis.min = settings.axisMinValue;
        if (context.settings.axisGridDistance != null)
            valueAxis.renderer.minGridDistance = context.settings.axisGridDistance;

        if (context.settings?.stacked && context.settings?.percentage)
            valueAxis = this.setPercentageAxis(valueAxis);
        else {
            valueAxis.numberFormatter.numberFormat = this.getMeasureNumberFormat(measureAppearance, settings);
            valueAxis.numberFormatter.bigNumberPrefixes = this.getBigNumberPrefixes(measureAppearance, settings);
        }

        valueAxis.showOnInit = true;
        valueAxis.keepSelection = true;

        this.showValueAxisTooltip(valueAxis, context.settings.showValueAxisTooltip);
        this.setFixedBigNumberValueAxis(valueAxis, measureAppearance, settings);
        this.setLabelVisibility(valueAxis, settings);

        return valueAxis;
    }

    updateValueAxes(context: any) {

        for (let i = 0; i < context.measures.length; i++) {
            let measure = context.measures[i];
            let measureResultName = measure.resultName || "value" + i;
            let measureAppearance = context.settings.measures?.length
                ? context.settings.measures?.find((m: any) => m.name === measure.resultName)
                : {};
            let isOpposite = measureAppearance?.hasOwnAxis ? measureAppearance.axisPosition === "opposite" : false;

            let xAxis = this.chart.xAxes?.values?.find((axis: any) => axis.dataFields.resultName === measureResultName);

            if (xAxis !== undefined && xAxis !== null)
                xAxis.renderer.opposite = isOpposite;

            this.updateValueAxis(context, xAxis, measure);
        }

        this.baseSyncAxesByZero(context, this.chart.xAxes);
    }

    createCategoryAxis(categoryFieldName: any, context: any) {
        let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = categoryFieldName;
        categoryAxis.tooltip.label.maxWidth = 200;
        categoryAxis.tooltip.label.wrap = true;
        categoryAxis.cursorTooltipEnabled = false;
        categoryAxis.renderer.labels.template.adapter.add("text", (label: any, target: any) => {
            if (label)
                label = label?.replace('{', '')?.replace('}', '');

            return this.dateCategoryFormatter(label);
        });

        this.updateCategoryAxisSettings(categoryAxis, context);
        return categoryAxis;
    }

    removeCategoryAxis(index: any) {
        this.chart.yAxes.removeIndex(index);
    }

    createSerieses(context: any) {
        let i = 0;
        let j = 0;
        let me = this;
        let measures = context.measures;
        let dimensions = context.dimensions;

        measures.forEach(function (measure: any) {
            let valueFieldName = measure.resultName || "value" + i;
            let isDelta = measure?.valueKind != 0;
            dimensions.forEach((dimension: any) => {
                let dimensionResultName = dimension.resultName || "key" + j++;
                me.createSeries(valueFieldName, dimensionResultName, context, isDelta, i);
            });
        });
    }

    createSeries(valueFieldName: any, categoryFieldName: any, context: any, canUseDelta: any, i: any) {
        const definedFieldName = canUseDelta ? "_delta_" + valueFieldName : valueFieldName;
        const { settings, measures, dimensions, isCluster } = context;
        const seriesType = this.getSeriesType(valueFieldName, settings);
        const measure = measures.find((f: any) => f.resultName == valueFieldName);
        const measureAppearance = this.context.isCluster ? settings.measures[0] : settings.measures.find((f: any) => f.name == valueFieldName);
        const bigPrefixes = this.getBigNumberPrefixes(measureAppearance, settings);
        const basicMeasures = context.basicMeasures ?? measures;

        let numberFormat = this.getMeasureNumberFormat(measureAppearance, settings);
        numberFormat += this.percentSignIfNeeded(numberFormat, measure, canUseDelta, basicMeasures, isCluster, i);

        let series: any = null;
        series = this.createSeriesByType(seriesType, measure, numberFormat, bigPrefixes);

        const dimension = dimensions.find((d: any) => d.resultName == categoryFieldName);
        //const dimensionIsDateTime = dimension?.dataType == 3;
        const category = /*dimensionIsDateTime ? "dateY" :*/ "categoryY";
        const valueFormat = settings.stacked && settings.percentage ? "{valueX.totalPercent.formatNumber('" + numberFormat.replace(/%/g, "") + "')}%" : "{valueX.formatNumber('" + numberFormat + "')}";

        if (series instanceof am4charts.ColumnSeries)
            this.setSeriesColumnProperties(series, settings, category, valueFormat, measureAppearance, context, numberFormat);
        else
            this.setSeriesLineProperties(series, settings, category, valueFormat, measureAppearance, context);

        this.setFixedBigNumberTooltip(series, measureAppearance, settings);
        //if (dimensionIsDateTime)
        //    series.dataFields.dateY = categoryFieldName;
        //else
        series.dataFields.categoryY = categoryFieldName;

        series.dataFields.valueX = definedFieldName;

        this.appendThreshold(context, series, valueFieldName);
        this.updateSeries(context, valueFieldName);

        let hasOwnAxis = measureAppearance?.hasOwnAxis;
        series.xAxis = this.getAppropriateXAxis(definedFieldName, hasOwnAxis, canUseDelta);

        this.setSeriesSettings(series, settings, valueFormat, context);

        return series;
    }

    createSeriesByType(seriesType: any, measure: any, numberFormat: any, bigPrefixes: any) {
        let series = !seriesType || seriesType == 'bar' ? this.chart.series.push(new am4charts.ColumnSeries()) : this.chart.series.push(new am4charts.LineSeries());
        series.name = this.getSeriesName(measure);
        series.numberFormatter = new am4core.NumberFormatter();
        series.numberFormatter.numberFormat = numberFormat;
        series.numberFormatter.bigNumberPrefixes = bigPrefixes;

        return series;
    }

    private setSeriesColumnProperties(series: any, settings: any, category: any, valueFormat: any, measureAppearance: any, context: any, numberFormat: any) {
        let prefix = this.getPrefix(context, measureAppearance, settings);
        let suffix = this.getSuffix(context, measureAppearance, settings);
        let template = series.columns.template;
        const isStacked = settings?.stacked ?? false;
        const useCornerRadius = !isStacked;

        this.setCornerRadius(useCornerRadius, template.column);

        template.fillOpacity = .8;
        template.strokeWidth = 1;
        template.strokeOpacity = 1;
        let tooltipText = settings.isCluster
            ? `${this.dateCategoryFormatter(category)} - {name}: [bold]${prefix}${valueFormat}${suffix}[/]`
            : `${this.dateCategoryFormatter(category)}: [bold]${prefix}${valueFormat}${suffix}[/]`;

        if (settings.stacked && settings.percentage && settings.showMultiTooltips)
            series.tooltipText = tooltipText;
        else
            template.tooltipText = tooltipText;

        if (!context.settings.showMultiTooltips)
            series.cursorTooltipEnabled = false;

        this.appendColumnActiveState(series);
        this.onClickColumnHandler(this.chart, series, context);
    }

    setSeriesLineProperties(series: any, settings: any, category: any, valueFormat: any, measureAppearance: any, context: any) {
        let prefix = this.getPrefix(context, measureAppearance, settings);
        let suffix = this.getSuffix(context, measureAppearance, settings);
        let template = series;
        template.strokeWidth = 2;
        template.minBulletDistance = 0;

        if (measureAppearance)
            series.fillOpacity = measureAppearance.fillArea ? 0.3 : undefined;

        let tooltipText = `${this.dateCategoryFormatter(category)}: [bold]${prefix}${valueFormat}${suffix}[/]`;

        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 2;
        bullet.circle.radius = 4;
        bullet.circle.fill = am4core.color("#fff");
        bullet.tooltipText = tooltipText;
        let bullethover = bullet.states.create("hover");
        bullethover.properties.scale = 1.3;

        if (settings.stacked && settings.percentage && settings.showMultiTooltips)
            series.tooltipText = tooltipText;
        else
            template.tooltipText = tooltipText;

        template.tooltip.pointerOrientation = "vertical";
        template.tooltip.label.textAlign = "middle";
        template.tooltip.label.textValign = "middle";
    }

    setSeriesSettings(series: any, settings: any, valueFormat: any, context: any) {
        if (settings.stacked && settings.percentage) {
            series.calculatePercent = true;
            series.legendSettings.itemValueText = settings.showLegendPercent ? valueFormat : "";
            series.dataFields.valueXShow = "totalPercent";
        }

        let firstDimension = context.settings?.dimensions == undefined ? null : settings?.dimensions[0];
        if (firstDimension?.useGradient && context.settings?.dimensions?.length == 1)
            series.columns.template.adapter.add("fill", (fill: any, target: any) => target.dataItem ? this.getThemeColors(context)[target.dataItem.index] : fill);
    }

    updateSeries(context: any, valueFieldName?: any) {
        let settings = context.settings;
        this.chart.series.each((series: any) => {
            if (settings.measures != null) {
                let measureAppearance = this.context.isCluster ? settings.measures[0] : settings.measures.find((f: any) => f.name == valueFieldName);
                if (measureAppearance != null && series.columns != null)
                    series.columns.template.fill = measureAppearance.color;
                this.setBullets(series, settings, measureAppearance);
            }
        });
        Amcharts.prototype.updateSeries.call(this, context)
    }

    setBullets(series: any, settings: any, measureAppearance: any) {
        const bullet = series.bullets.push(new am4charts.LabelBullet());

        if (this.isNeedToShowLabels(this.showLabelsFromApp, this.context.showLabels) || this.context.showLabels) {
            let label = bullet.label;
            if (!settings.labelBulletPosition || settings.labelBulletPosition === "None") {
                label.disabled = true;
                return;
            }

            let prefix = this.getPrefix(this.context, measureAppearance, settings);
            let suffix = this.getSuffix(this.context, measureAppearance, settings);

            let numberFormat = this.getMeasureNumberFormat(measureAppearance, settings);
            const value = settings.stacked && settings.percentage
                ? "{valueX.totalPercent.formatNumber('" + numberFormat.replace(/%/g, "") + "')}%"
                : `${prefix}{valueX}${suffix}`;

            label.disabled = false;
            label.text = value;
            label.fill = am4core.color(settings.labelBulletColor || 'black');
            label.fontWeight = 'bold';
            label.hideOversized = false;
            label.truncate = false;

            this.addBulletAdapters(label, settings, measureAppearance);
        }

    }

    addBulletAdapters(label: any, settings: any, measureAppearance: any) {
        label.adapter.add("horizontalCenter", (center: any, target: any) => {
            if (!target.dataItem) return center;

            const position = settings.labelBulletPosition;
            const value = target.dataItem.values.valueX.value;
            const isInside = position === 'inside';
            const moreZero = isInside ? "right" : "left";
            const lessZero = isInside ? "left" : "right";

            return value > 0 ? moreZero : lessZero;
        });

        label.adapter.add("dx", (dx: any, target: any) => {
            if (!target.dataItem) return dx;

            const coef = 10;
            const position = settings.labelBulletPosition;
            const value = target.dataItem.values.valueX.value;
            const isInside = position === 'inside';
            const moreZero = isInside ? -coef : coef;
            const lessZero = isInside ? coef : -coef;

            return value > 0 ? moreZero : lessZero;
        });

        this.setFixedBigNumberLabel(label, measureAppearance, settings);
    }

    getSeriesType(measureName: any, settings: any) {
        if (settings && settings.measures) {
            let measureAppearance = this.context.isCluster ? settings.measures.find((m: any) => this.context.basicMeasures.find((x: any) => x.resultName == m.name)) : settings.measures.find((m: any) => measureName.includes(m.name));
            if (measureAppearance) {
                return measureAppearance.seriesType;
            }
        }
    }

    updateSettings(context: any) {
        super.updateSettings(context);

        let settings = context.settings;

        if (settings) {
            this.updateCategoryAxis(context);
            this.updateValueAxes(context);
            this.removeSerieses();
            this.createSerieses(context);
            this.updateMeasures(context);
            this.updateDimensions(settings);
            this.setLegend(settings);
        }
    }
    updateDimensions(settings: any) {
        if (settings == null || settings.dimensions == null) return;

        let dimensions = settings.dimensions;
        if (settings.dimensions.length) {
            for (let dimension of dimensions) {
                for (let j = 0; j < this.chart.yAxes.length; j++) {
                    let yAxis = this.chart.yAxes.getIndex(j);
                    if (yAxis.dataFields.category == dimension.name) {
                        yAxis.title.text = dimension.title;
                    }
                }
            }
        }
    }
    updateMeasures(context: any) {
        let settings = context.settings || {};
        if (!settings?.measures) return;

        const measureAppearances = settings.measures.filter((m: any) => m.title !== '');
        if (measureAppearances.length) {
            for (const measureAppearance of measureAppearances) {
                for (let j = 0; j < this.chart.series.length; j++) {
                    const serie = this.chart.series.getIndex(j);
                    if (serie.dataFields.valueX.includes(measureAppearance.name)) {
                        serie.name = this.translateFromMeasure(context, measureAppearance, "title");
                    }
                }
            }
        }
    }

    updateCategoryAxisSettings(categoryAxis: am4charts.CategoryAxis, context: any) {
        let dimension = context.settings?.dimensions?.length ? context.settings.dimensions?.filter((d: any) => d.name != null && d.categoryLabelRotation)[0] : {};
        categoryAxis.renderer.minGridDistance = dimension?.categoryLabelDistance ?? 0;

        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.title.fontWeight = "bold";
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;
        categoryAxis.renderer.grid.template.location = 0;

        let label = categoryAxis.renderer.labels.template;
        label.tooltipText = `${this.dateCategoryFormatter(`category`)}`;
        label.truncate = true;
        label.horizontalCenter = "right";
        label.minWidth = context.settings.yAxisLabelWidth;

        let isCluster = context.isCluster;
        categoryAxis.renderer.cellStartLocation = isCluster ? context.cellStartLocation : 0;
        categoryAxis.renderer.cellEndLocation = isCluster ? context.cellEndLocation : 1;
        categoryAxis.title.fontWeight = "bold";
        let firstDimension = context.settings?.dimensions?.length ? context.settings.dimensions[0] : undefined;
        categoryAxis.numberFormatter = new am4core.NumberFormatter();
        categoryAxis.numberFormatter.numberFormat = this.getDimensionNumberFormat(firstDimension);
        categoryAxis.cursorTooltipEnabled = false;
    }
    updateCategoryAxis(context: any) {
        if (context.settings == null)
            return;

        let categoryAxis = this.chart.yAxes.getIndex(0);
        if (categoryAxis)
            this.updateCategoryAxisSettings(categoryAxis, context);
    }
    updateValueAxis(context: any, valueAxis: any, measure: any = null) {
        if (context?.settings == null) return;

        let firstAxis = this.chart.xAxes?.values?.[0];
        let measureAppearance = context.settings.measures?.find((m: any) => m.name === measure.resultName);

        if (valueAxis) {
            valueAxis.title.fontWeight = "bold";
            valueAxis.title.text = this.translateFromMeasure(context, measureAppearance, "axisTitle");
            valueAxis.numberFormatter = new am4core.NumberFormatter();
            valueAxis.max = context.settings.axisMaxValue;
            valueAxis.min = context.settings.axisMinValue;
            if (context.settings.axisGridDistance != null)
                valueAxis.renderer.minGridDistance = context.settings.axisGridDistance;

            if (context.settings?.stacked && context.settings?.percentage)
                valueAxis = this.setPercentageAxis(valueAxis);
            else {
                valueAxis.numberFormatter.numberFormat = this.getMeasureNumberFormat(measureAppearance, context.settings);
                valueAxis.numberFormatter.bigNumberPrefixes = this.getBigNumberPrefixes(measureAppearance, context.settings);
            }

        }
        if (valueAxis && valueAxis === firstAxis)
            valueAxis.title.text = this.translateFromMeasure(context, measureAppearance, "axisTitle") || this.translateFromSettings(context, "valueAxisTitle");
    }

    setData(context: any) {
        if (context.data) {
            this.chart.colors.reset();
            this.chart.data = context.data;
            if (context.data.length) {
                this.setLegend(context.settings);
                this.updateValueAxes(context);
                this.syncValueAxes(context);
            }
        }
    }

    markActiveByValue(values: any[]) {
        setTimeout(() => {
            this.chart.series.each((s: any) => s.columns.each((c: any, _i: number) => {
                values.forEach((v: any) => {
                    if (v == c.dataItem.categoryY)
                        c.isActive = true;
                })
            }));
        }, 100);
    }   

    getAppropriateXAxis(valueFieldName: any, hasOwnAxis: boolean, isDeltaAxis: boolean) {
        if (hasOwnAxis || isDeltaAxis)
            return this.getActiveAxis(this.chart.xAxes, valueFieldName);
        else {
            const measureAppearancesWithOwnAxes = this.context?.settings?.measures?.filter((m: any) => m.hasOwnAxis);
            const measureAppearancesWithDeltaAxes = this.context?.measures?.filter((m: any) => m.valueKind != 0);

            const xAxesWithMultipleSpecial = this.getMarkedMultipleAxes(this.chart.xAxes, measureAppearancesWithOwnAxes,
                { measuresSettingsWithDeltaAxes: measureAppearancesWithDeltaAxes, mode: "special" });

            const appropriateAxis = xAxesWithMultipleSpecial.find((axis: any) => (axis !== null && axis !== undefined));
            return appropriateAxis ?? this.chart.xAxes.values[0];
        }
    }

    syncValueAxes(context: any) {
        const values = this.chart.xAxes.values;
        if (values?.length > 0) {
            const mainAxis = this.getMainValueAxis(context);
            this.baseSyncValueAxes(this.chart.xAxes, mainAxis);
        }
    }

    getMainValueAxis(context: any): any {
        const measureAppearancesWithOwnAxes = context?.settings?.measures?.filter((m: any) => m.hasOwnAxis);
        const measureAppearancesWithDeltaAxes = context?.measures?.filter((m: any) => m.valueKind != 0);

        const xAxesWithMultipleGeneral = this.getMarkedMultipleAxes(this.chart.xAxes, measureAppearancesWithOwnAxes,
            { measuresSettingsWithDeltaAxes: measureAppearancesWithDeltaAxes, mode: "general" });

        const mainAxis = xAxesWithMultipleGeneral.find((axis: any) => (axis !== null && axis !== undefined));
        return mainAxis ?? null;
    }

    removeValueAxisByResultName(resultName: string) {
        let xAxis = this.chart.xAxes?.values?.find((axis: any) => axis.dataFields.resultName === resultName);
        if (xAxis !== undefined) {
            let index = this.chart.yAxes.indexOf(xAxis);
            this.chart.yAxes.removeIndex(index);
        }
    }

    sortLegend(series: any) {
        series.events.once("ready", (ev: any) => {
            if (!ev.target.legendDataItem)
                return;

            let names = ev.target.chart.series.values.map((x: any) => x.name);
            let sortedNames = names.sort((x: any, y: any) => (x > y ? 1 : -1))
            let name = ev.target.legendDataItem.itemContainer.dataItem.name;
            console.log('name: ' + name);
            let index = sortedNames.indexOf(name) * 10;
            console.log('index: ' + index);

            ev.target.legendDataItem.itemContainer.zIndex = index;
            ev.target.chart.legend.children.moveValue(ev.target.legendDataItem.itemContainer, index);
        });
    }
}