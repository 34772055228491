import * as am4charts from '@amcharts/amcharts4/charts';
import { AmchartsBarchart } from './AmchartsBarchart';

export class AmchartsSignedBarchart extends AmchartsBarchart {

    constructor() {
        super();
    }

    createByRef(ref: any, context: any): any {
        let settings = context.settings || {};

        this.applyTheme(context, () => {
            this.create(ref, am4charts.XYChart, context);
        });

        this.chart.numberFormatter.numberFormat = (context.settings && context.settings.numberFormat) ? context.settings.numberFormat : "#,###.";
        this.chart.numberFormatter.numberFormat = this.chart.numberFormatter.numberFormat + 's';

        let valueAxis = this.createValueAxis(settings);
        this.updateValueAxis(valueAxis, context.settings);
        
        if (context.dimensions.length) {
            let dimension = context.dimensions[0];
            let dimensionResultName = dimension.resultName || "key0";
            let categoryAxis = this.createCategoryAxis(dimensionResultName, context.settings);
            categoryAxis.renderer.inversed = true;

            context.dimensions.forEach((_dimension: any, i: any) => {
                this.createByDimension(_dimension, i, context)
            });
        }
        this.updateMeasures(settings);

        return this;
    }

    //recreate(ref: any, context: any) {
    //    this.dispose();
    //    this.createByRef(ref, context);
    //}

    createByDimension(dimension: any, i: any, context: any) {
        let measureNegativeResultName = context.measures[0].resultName || "value";
        let measurePositiveResultName = context.measures[1].resultName || "value";
        let dimensionResultName = dimension.resultName || "key" + i;
        let negativeSeries = this.createSeries(measureNegativeResultName, dimensionResultName, context);
        let positiveSeries = this.createSeries(measurePositiveResultName, dimensionResultName, context);
        if (negativeSeries.columns) 
            this.onHit(negativeSeries, context);
        if (positiveSeries.columns)
            this.onHit(positiveSeries, context);
    }

    onHit(series: any, context: any) {
        series.columns.template.events.on("hit", function (ev: any) {
            console.log("clicked on ", ev.target.column.dataItem.dataContext);
            return context.onClickColumnCaller
                .invokeMethodAsync(context.onClickColumnFn, ev.target.column.dataItem.dataContext)
                .then((r: any) => console.log(r));
        }, this);
    }
 
    createCategoryAxis(categoryFieldName: any, _settings: any) {
        let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = categoryFieldName;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.inversed = true;
        let label = categoryAxis.renderer.labels.template;
        label.truncate = true;
        label.maxWidth = 130;
        label.tooltipText = "{category}";
        return categoryAxis;
    }

    createValueAxis(settings: any) {
        let valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = settings.ValueAxisTitle;

        return valueAxis;
    }

    createSeries(valueFieldName: any, categoryFieldName: any, context: any) {
        
        let series = this.chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = valueFieldName;
        series.dataFields.categoryY = categoryFieldName;
        series.clustered = false;

        let template = series.columns.template;
        template.opacity = .8;
        template.tooltipText = "{categoryY}: [bold]{valueX}[/]";
        template.strokeWidth = 1;
        template.strokeOpacity = 1;
        this.updateSeries(context);
        return series;
    }
}