import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { Amcharts } from './Amcharts';

export class AmchartsSparkline extends Amcharts {

    container: any | undefined;
    colors: any | undefined;

    constructor() {
        super();
        this.container = null;
        this.colors = null;
    }

    createByRef(ref: any, context: any): any {
        this.container = am4core.create(ref, am4core.Container);
        this.container.layout = "grid";
        this.container.fixedWidthGrid = false;
        this.container.width = am4core.percent(100);
        this.container.height = am4core.percent(100);
        this.container.background.opacity = 0;
        this.container.background.fillOpacity = 0;
        this.colors = new am4core.ColorSet();

        return this;
    }

    //recreate(ref: any, context: any) {
    //    this.dispose();
    //    this.createByRef(ref, context);
    //}

    createLine(ref: any, context: any) {

        this.applyTheme(context, () => {
            this.create(ref, am4charts.XYChart, context);
        });

        let chart = this.container.createChild(am4charts.XYChart);
        chart.width = am4core.percent(50);
        chart.height = 140;

        chart.data = context.data;

        chart.titles.template.fontSize = 11;
        chart.titles.template.fontWeight = "bold";
        chart.titles.template.textAlign = "left";
        chart.titles.template.isMeasured = false;
        chart.titles.create().text = context.title;

        chart.padding(25, 15, 15, 15);

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.labels.template.disabled = true;
        dateAxis.startLocation = 0.5;
        dateAxis.endLocation = 0.7;
        dateAxis.cursorTooltipEnabled = false;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.cursorTooltipEnabled = false;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineY.disabled = true;
        chart.cursor.behavior = "none";

        let series = chart.series.push(new am4charts.LineSeries());
        series.tooltipText = "{date}: [bold]{value}";
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "value";
        series.tensionX = 1.0;
        series.strokeWidth = 2;
        series.stroke = this.colors.getIndex(0);

        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.opacity = 0;
        bullet.circle.fill = this.colors.getIndex(0);
        bullet.circle.propertyFields.opacity = "opacity";
        bullet.circle.radius = 3;

        return chart;
    }

    createSparkLine(ref: any, context: any) {
        const contextFormatted = this.contextFormatting(context);
        this.applyTheme(context, () => {
            this.create(ref, am4charts.XYChart, context);
        });
        this.chart.numberFormatter.numberFormat = this.numberFormat(context);

        this.createCategoryAxis(contextFormatted);
        this.createValueAxis();

        this.setData(contextFormatted);
        this.setSettings(contextFormatted);
        this.setCursor();

        const series = this.createSeries();
        this.createBullets(series);
        return this.chart;
    }

    createValueAxis() {
        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.cursorTooltipEnabled = false;
        return valueAxis;
    }

    createCategoryAxis(context: any) {
        let categoryAxis = this.chart.xAxes.push(new am4charts.DateAxis());
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.labels.template.disabled = true;
        categoryAxis.startLocation = 0.5;
        categoryAxis.endLocation = 0.7;
        categoryAxis.cursorTooltipEnabled = false;
        categoryAxis.baseInterval = {
            timeUnit: context.bin,
            count: 1
        };
        return categoryAxis;
    }

    createSeries() {
        let series = this.chart.series.push(new am4charts.LineSeries());
        series.tooltipText = "{dateX.formatDate('yyyy-MM-dd HH:mm:ss')}: [bold]{valueY.formatNumber('" + this.chart.numberFormatter.numberFormat  + "')}";
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "value";
        series.tensionX = 1;
        series.strokeWidth = 2;
        return series;
    }

    createBullets(series: any) {
        const bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.opacity = 0;
        bullet.circle.fill = this.colors.getIndex(0);
        bullet.circle.propertyFields.opacity = "opacity";
        bullet.circle.radius = 3;
        return bullet;
    }

    setData(context: any) {
        if (context.data) {
            this.chart.data = context.data;
        }
    }

    setSettings(context: any) {
        this.chart.width = am4core.percent(100);
        this.chart.height = context.height;
        this.chart.padding(0, 15, 0, 15);
    }

    setCursor() {
        this.chart.cursor = new am4charts.XYCursor();
        this.chart.cursor.lineY.disabled = true;
        this.chart.cursor.behavior = "none";
    }

    setTitles(context: any) {
        this.chart.titles.template.fontSize = 11;
        this.chart.titles.template.fontWeight = "bold";
        this.chart.titles.template.textAlign = "left";
        this.chart.titles.template.isMeasured = false;
        this.chart.titles.create().text = context.title;
    }

    createColumn(title: any, data: any, color: any) {

        let chart = this.container.createChild(am4charts.XYChart);
        chart.width = am4core.percent(45);
        chart.height = 70;

        chart.data = data;

        chart.titles.template.fontSize = 10;
        chart.titles.template.textAlign = "left";
        chart.titles.template.isMeasured = false;
        chart.titles.create().text = title;

        chart.padding(20, 5, 2, 5);

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.labels.template.disabled = true;
        dateAxis.cursorTooltipEnabled = false;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.cursorTooltipEnabled = false;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineY.disabled = true;

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.tooltipText = "{date}: [bold]{value}";
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "value";
        series.strokeWidth = 0;
        series.fillOpacity = 0.5;
        series.columns.template.propertyFields.fillOpacity = "opacity";
        series.columns.template.fill = color;

        return chart;
    }

    createPie(data: any, color: any) {

        let chart = this.container.createChild(am4charts.PieChart);
        chart.width = am4core.percent(10);
        chart.height = 70;
        chart.padding(20, 0, 2, 0);

        chart.data = data;

        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;
        pieSeries.slices.template.fill = color;
        pieSeries.slices.template.adapter.add("fill", function (fill: any, target: any) {
            return fill.lighten(0.1 * target.dataItem.index);
        });
        pieSeries.slices.template.stroke = am4core.color("#fff");

        return chart;
    }

    clearContainer() {
        this.container.disposeChildren();
    }

    contextFormatting(context: any): any {

        const formattedList = context.data.map((x: { date: string; value: number }) => ({
            date: new Date(x.date),
            value: x.value
        }));

        return {
            bin: context.bin,
            title: context.title,
            data: formattedList,
            height: context.height
        };
    }

    numberFormat(context: any) {
        let numberFormatAsInt = context?.settings?.numberFormat;
        switch (numberFormatAsInt) {
            case 0: return '#.0';
            case 1: return '#.00';
            case 2: return '#.000';
            case 3: return '#.###.';
            case 4: return '#,###.0';
            case 5: return '#,###.00';
            case 6: return '#,###.000';
            case 7: return '#,###.a';
            case 8: return '#,###.0a';
            case 9: return '#,###.00a';
            default:
                return '#,###.';
        }
    }
}

