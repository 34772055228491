import { Amcharts } from "./Amcharts";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud"; 

export class AmchartsWordCloudchart extends Amcharts {
    createByRef(ref: any, context: any) : any {
        context.settings.themeVariant = context.settings.themeVariant || 3;

        this.applyTheme(context.settings, () => {
            this.create(ref, am4plugins_wordCloud.WordCloud, context);
        });
        this.createSeries(context);
        return this;
    }

    //recreate(ref: any, context: any) {
    //    this.dispose();
    //    this.createByRef(ref, context);
    //}

    createSeries(context: any) {
        let series = this.chart.series.push(new am4plugins_wordCloud.WordCloudSeries());
        if (context.measures.length != 0 && context.dimensions.length != 0) {
            series.dataFields.word = context.dimensions[0].resultName;
            series.dataFields.value = context.measures[0].resultName;
        }
    }

    setData(context: any) {
        if (context.data)
            this.chart.series.values[0].data = context.data;        
    }
}