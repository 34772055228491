import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { AmchartsPiechart } from './AmchartsPiechart';
import { AmchartsBarchart } from './AmchartsBarchart';
import { AmchartsSignedBarchart } from './AmchartsSignedBarchart';
import { AmchartsColumnchart } from './AmchartsColumnchart';
import { AmchartsDumbbellColumnchart } from './AmchartsDumbbellColumnChart';
import { AmchartsTimechart } from './AmchartsTimechart';
import { AmchartsSparkline } from './AmchartsSparkline';
import { AmchartsTreemap } from "./AmchartsTreemap";
import { AmchartsGaugechart } from "./AmchartsGaugechart";
import { AmchartsWordCloudchart } from "./AmchartsWordCloudchart";


export function Load() {

    (window as any).AmChartEngine = {

        createPieChartByRef: function (ref: any, context: any) {
            let pieChart = new AmchartsPiechart().createByRef(ref, context);
            pieChart.setData(context);
            (window as any).ItemsManager.add(ref, pieChart);
        },

        createBarChart1: function (ref: any, context: any) {
            let chart = am4core.create(ref, am4charts.XYChart);
            (window as any).ItemsManager.add(ref, chart);
            if (context.data)
                chart.data = context.data;

            let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.title.text = context.categoryTitle;
            categoryAxis.dataFields.category = "key";
            categoryAxis.renderer.inversed = true;
            categoryAxis.renderer.grid.template.location = 0;

            let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.opposite = true;

            for (let i = 0; i < context.aggregations.length; i++) {
                let agg = context.aggregations[i];
                let series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.categoryY = "key";
                series.dataFields.valueX = "value" + i;
                series.name = agg.title;
                series.columns.template.fillOpacity = 0.8;
                series.columns.template.strokeOpacity = 1;
                series.hiddenState.properties.opacity = 1;

                series.columns.template.events.on("hit", function (ev: any) {
                    console.log("clicked on ", ev.target.column.dataItem.dataContext);
                    return context.onClickColumnCaller
                        .invokeMethodAsync(context.onClickColumnFn, ev.target.column.dataItem.dataContext.key)
                        .then((r: any) => console.log(r));
                }, this);
            }

            chart.legend = new am4charts.Legend();
        },

        createBarChartByRef: function (ref: any, context: any) {
            let barChart = new AmchartsBarchart().createByRef(ref, context);
            barChart.setData(context);
            (window as any).ItemsManager.add(ref, barChart);
        },

        createSignedBarChartByRef: function (ref: any, context: any) {
            let signedBarChart = new AmchartsSignedBarchart().createByRef(ref, context);
            signedBarChart.setData(context);
            (window as any).ItemsManager.add(ref, signedBarChart);
        },

        createColumnChartByRef: function (ref: any, context: any) {
            let columnChart = new AmchartsColumnchart().createByRef(ref, context);
            columnChart.setData(context);
            (window as any).ItemsManager.add(ref, columnChart);
        },

        createDumbbellColumnChartByRef: function (ref: any, context: any) {
            let dumbbellColumnChart = new AmchartsDumbbellColumnchart().createByRef(ref, context);
            dumbbellColumnChart.setData(context);
            (window as any).ItemsManager.add(ref, dumbbellColumnChart);
        },

        createTimeChart: function (ref: any, context: any) {
            let chart = am4core.create(ref, am4charts.XYChart);
            (window as any).ItemsManager.add(ref, chart);
            if (context.data)
                chart.data = context.data;            

            chart.dateFormatter.inputDateFormat = "yyyy-MM-ddThh:mm:ssZ";

            let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

            dateAxis.title.text = "År";
            valueAxis.title.text = "NOK 2018-verdi";
            chart.legend = new am4charts.Legend();
        },

        createTimeChartByRef: function (ref: any, context: any) {
            let timeChart = new AmchartsTimechart().createByRef(ref, context);
            timeChart.setData(context);
            (window as any).ItemsManager.add(ref, timeChart);
        },

        disposeByRef: function (ref: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.dispose();
        },

        exportByRef: function (ref: any, exportType: string, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.export(exportType, context);
        },

        setThemeByRef: function (ref: any, theme: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.setTheme(theme);
        },

        showIndicatorByRef: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.showIndicator(context);
        },

        hideIndicatorByRef: function (ref: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.hideIndicator();
        },

        updateSettingsByRef: function (ref: any, settings: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.updateSettings(settings);
        },

        recreateByRef: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.recreate(ref, context);
        },

        createTimeSerie: function (title: any) {
            let series = new am4charts.LineSeries();
            series.name = title;
            series.tooltipText = "{key}={value1}";
            series.strokeWidth = 2;
            series.showOnInit = false;
            return series;
        },

        loadTimeData: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            let size = 0;
            context.data.forEach(function (item: any) {
                let itemSize = 0;
                Object.keys(item).forEach(function (i) {
                    if (i.startsWith('value'))
                        itemSize++;                    
                });
                if (itemSize > size)
                    size = itemSize;                
            });

            while (chart.series.length) 
                chart.series.removeIndex(0).dispose();
                
            for (let i = 0; i < size; i++) {
                let series = this.createTimeSerie(context.data[0]["name" + i]);
                series.dataFields.valueY = "value" + i;
                series.dataFields.dateX = "key";
                chart.series.push(series);
            }

            chart.invalidateData();
            chart.data = context.data || [];
        },

        setTitle: function (ref: any, title: any) {
            let chart = (window as any).ItemsManager.get(ref);
            if (chart)
                chart.titles.getIndex(0).text = title;            
        },

        setXAxisTitle: function (ref: any, title: any) {
            let chart = (window as any).ItemsManager.get(ref);
            if (chart)
                chart.xAxes.getIndex(0).title.text = title;            
        },

        setYAxisTitle: function (ref: any, title: any) {
            let chart = (window as any).ItemsManager.get(ref);
            if (chart)
                chart.yAxes.getIndex(0).title.text = title;            
        },

        extractSeriesDatas: function (datas: any, keyName: any, valueName: any) {
            let result = [];
            for (let data of datas) {
                let item = {};
                (item as any).keyName = data[keyName];
                (item as any).valueName = data[valueName];
                result.push(item);
            }
            return result;
        },

        createValueAxisByRef: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.createValueAxis(context);
        },
        createDeltaValueAxisByRef: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.createDeltaValueAxis(context);
        },
        removeValueAxisByRef: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.removeValueAxis(context);
        },
        removeValueAxisByNameByRef: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.removeValueAxisByResultName(context.resultName);
        },
        checkValueAxesByRef: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.checkValueAxes(context);
        },
        createCategoryAxisByRef: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            let categoryFieldName = context.dimension.resultName || "key";
            chart?.createCategoryAxis(categoryFieldName, context);
        },
        removeCategoryAxisByRef: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            if (typeof chart.removeCategoryAxis === "function") {
                chart?.removeCategoryAxis(context.index);
            }
        },
        replaceCategoryAxisByRef: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.removeCategoryAxis(context.index);
            chart?.createCategoryAxis(context.categoryFieldName, context);
        },
        createSeriesesByRef: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart.createSerieses(context.measures, context.categoryFieldName);
        },
        createSeriesByRef: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            let valueFieldName = context.measure.resultName;
            let categoryFieldName = context?.categoryFieldName;
            chart?.createSeries(valueFieldName, categoryFieldName, context);
        },
        removeSeriesByRef: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.removeSeries(context.index);
        },
        removeSeriesesByRef: function (ref: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.removeSerieses();
        },
        loadDataByRef: function (ref: any, context: any) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.setData(context);
        },

        markActiveByValueByRef: function (ref: any, values: any[]) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.markActiveByValue(values);
        },

        createTimeSeriesTemp: function (chart: any, _seriesData: any) {
            let data = [{
                "date": "2013-01-16",
                "market1": 71,
                "market2": 75,
                "sales1": 5,
                "sales2": 8
            }, {
                "date": "2013-01-17",
                "market1": 74,
                "market2": 78,
                "sales1": 4,
                "sales2": 6
            }, {
                "date": "2013-01-18",
                "market1": 78,
                "market2": 88,
                "sales1": 5,
                "sales2": 2
            }, {
                "date": "2013-01-19",
                "market1": 85,
                "market2": 89,
                "sales1": 8,
                "sales2": 9
            }, {
                "date": "2013-01-20",
                "market1": 82,
                "market2": 89,
                "sales1": 9,
                "sales2": 6
            }, {
                "date": "2013-01-21",
                "market1": 83,
                "market2": 85,
                "sales1": 3,
                "sales2": 5
            }, {
                "date": "2013-01-22",
                "market1": 88,
                "market2": 92,
                "sales1": 5,
                "sales2": 7
            }, {
                "date": "2013-01-23",
                "market1": 85,
                "market2": 90,
                "sales1": 7,
                "sales2": 6
            }, {
                "date": "2013-01-24",
                "market1": 85,
                "market2": 91,
                "sales1": 9,
                "sales2": 5
            }, {
                "date": "2013-01-25",
                "market1": 80,
                "market2": 84,
                "sales1": 5,
                "sales2": 8
            }, {
                "date": "2013-01-26",
                "market1": 87,
                "market2": 92,
                "sales1": 4,
                "sales2": 8
            }, {
                "date": "2013-01-27",
                "market1": 84,
                "market2": 87,
                "sales1": 3,
                "sales2": 4
            }, {
                "date": "2013-01-28",
                "market1": 83,
                "market2": 88,
                "sales1": 5,
                "sales2": 7
            }, {
                "date": "2013-01-29",
                "market1": 84,
                "market2": 87,
                "sales1": 5,
                "sales2": 8
            }, {
                "date": "2013-01-30",
                "market1": 81,
                "market2": 85,
                "sales1": 4,
                "sales2": 7
            }];

            let series1Data: any = data.map(function (i) { return { 'date': i.date, 'sales1': i.sales1 } });
            let series2Data = data.map(function (i) { return { 'date': i.date, 'sales2': i.sales2 } });
            let markets1Data = data.map(function (i) { return { 'date': i.date, 'market1': i.market1 } });
            let markets2Data = data.map(function (i) { return { 'date': i.date, 'market2': i.market2 } });

            let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis1.title.text = "Sales";

            let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis2.title.text = "Market Days";
            valueAxis2.renderer.opposite = true;
            valueAxis2.renderer.grid.template.disabled = true;

            let series1 = chart.series.push(new am4charts.ColumnSeries());
            series1.dataFields.valueY = "sales1";
            series1.dataFields.dateX = "date";
            series1.yAxis = valueAxis1;
            series1.name = "Target Sales";
            series1.tooltipText = "{name}\n[bold font-size: 20]${valueY}M[/]";
            series1.fill = chart.colors.getIndex(0);
            series1.strokeWidth = 0;
            series1.clustered = false;
            series1.columns.template.width = am4core.percent(40);
            series1.data = series1Data;

            let series2 = chart.series.push(new am4charts.ColumnSeries());
            series2.dataFields.valueY = "sales2";
            series2.dataFields.dateX = "date";
            series2.yAxis = valueAxis1;
            series2.name = "Actual Sales";
            series2.tooltipText = "{name}\n[bold font-size: 20]${valueY}M[/]";
            series2.fill = chart.colors.getIndex(0).lighten(0.5);
            series2.strokeWidth = 0;
            series2.clustered = false;
            series2.data = series2Data;
            series2.toBack();

            let series3 = chart.series.push(new am4charts.LineSeries());
            series3.dataFields.valueY = "market1";
            series3.dataFields.dateX = "date";
            series3.name = "Market Days";
            series3.strokeWidth = 2;
            series3.tensionX = 0.7;
            series3.yAxis = valueAxis2;
            series3.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";
            series3.data = markets1Data;

            let bullet3 = series3.bullets.push(new am4charts.CircleBullet());
            bullet3.circle.radius = 3;
            bullet3.circle.strokeWidth = 2;
            bullet3.circle.fill = am4core.color("#fff");

            let series4 = chart.series.push(new am4charts.LineSeries());
            series4.dataFields.valueY = "market2";
            series4.dataFields.dateX = "date";
            series4.name = "Market Days ALL";
            series4.strokeWidth = 2;
            series4.tensionX = 0.7;
            series4.yAxis = valueAxis2;
            series4.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";
            series4.stroke = chart.colors.getIndex(0).lighten(0.5);
            series4.strokeDasharray = "3,3";
            series4.data = markets2Data;

            let bullet4 = series4.bullets.push(new am4charts.CircleBullet());
            bullet4.circle.radius = 3;
            bullet4.circle.strokeWidth = 2;
            bullet4.circle.fill = am4core.color("#fff");

            chart.cursor = new am4charts.XYCursor();

            chart.legend = new am4charts.Legend();
            chart.legend.position = "top";

            chart.scrollbarX = new am4charts.XYChartScrollbar();
            chart.scrollbarX.series.push(series1);
            chart.scrollbarX.series.push(series3);
            chart.scrollbarX.parent = chart.bottomAxesContainer;
        },

        createTreeMapByRef: function (ref: any, context: any) {
            let treeMap = new AmchartsTreemap().createByRef(ref, context);
            treeMap.setData(context);
            (window as any).ItemsManager.add(ref, treeMap);
        },

        createGaugeChartByRef: function (ref: any, context: any) {
            let gauge = new AmchartsGaugechart().createByRef(ref, context);
            gauge.setData(context);
            (window as any).ItemsManager.add(ref, gauge);
        },

        createWordCloudChartByRef: function (ref: any, context: any) {
            let wordCloud = new AmchartsWordCloudchart().createByRef(ref, context);
            wordCloud.setData(context);
            (window as any).ItemsManager.add(ref, wordCloud);
        },

        createSparklineContainerByRef: function (ref: any, context: any) {
            if (ref) {
                let sparkline = new AmchartsSparkline().createByRef(ref, context);
                (window as any).ItemsManager.add(ref, sparkline);
            }
        },

        createSparklineByRef: function (ref: any, context: any) {
            let sparkline = (window as any).ItemsManager.get(ref);
            sparkline?.createSparkLine(ref, context);
        },

        clearSparklineContainerByRef: function (ref: any) {
            let sparkline = (window as any).ItemsManager.get(ref);
            if (sparkline) {
                sparkline.clearContainer();
            }
        },

        setLabelsStatusByRef: function (ref: any, showLabels: boolean) {
            let chart = (window as any).ItemsManager.get(ref);
            chart?.setLabelsStatus(showLabels);
        }
    };
}