import { Load } from './AmchartsEngine';

namespace SignalHub.Browser.Charts {

    export function Main() {

        Load();
    }
}

SignalHub.Browser.Charts.Main();

