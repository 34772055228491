import { Amcharts } from "./Amcharts";
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

export class AmchartsTreemap extends Amcharts {

    constructor() {
        super();

        this.whenReady((_ev: any) => {
            this.chart.events.on("datavalidated", (_e: any) => {
                this.chart.yAxes.each((valueAxis: any) => {
                    valueAxis.zoomable = false;
                    valueAxis.toggleZoomOutButton = false;
                });
            });
        });
    }

    selectedBlocks: any[] = [];

    createByRef(ref: any, context: any) : any {
            this.applyTheme(context, () => this.create(ref, am4charts.TreeMap, context));
            for (let i = 0; i < context.measures.length; i++) {
                const measure = context.measures[i];

                for (let j = 0; j < context.dimensions.length; j++) {
                    const dimension = context.dimensions[j];

                    let measureResultName = measure.resultName || "value" + i;
                    let dimensionResultName = dimension.resultName || "key" + j;

                    this.createSeries(measureResultName, dimensionResultName, context);
                }
        }
        this.chart.zoomable = false;

        return this;
    }

    //recreate(ref: any, context: any) {
    //    this.dispose();
    //    this.createByRef(ref, context);
    //}

    createSeries(valueFieldName: any, categoryFieldName: any, context: any) {
        let settings = context?.settings;
        let measureAppearance = settings.measures.find((f: any) => f.name == valueFieldName);
        let prefix = this.getPrefix(context, measureAppearance, settings);
        let suffix = this.getSuffix(context, measureAppearance, settings);
        let isNeedToShowLabelsInside = settings.labelBulletPosition == "inside" && (this.isNeedToShowLabels(this.showLabelsFromApp, this.context.showLabels) || this.context.showLabels);
        this.chart.dataFields.name = categoryFieldName;
        this.chart.dataFields.value = valueFieldName;

        const series = this.chart.seriesTemplates.create("0");
        this.appendTreeMapColumnActiveState(series);
        this.onClickTreeMapHandler(this.chart, series, context);

        const columnsTemplate = series.columns.template;
        columnsTemplate.column.cornerRadius(10, 10, 10, 10);
        columnsTemplate.fillOpacity = 0.8;
        columnsTemplate.stroke = am4core.color("#FFFFFF");
        columnsTemplate.strokeWidth = 5;
        columnsTemplate.strokeOpacity = 1;
        columnsTemplate.tooltipText = `{name}: [bold]${prefix}{value}${suffix}[/]`

        const levelBullet = series.bullets.push(new am4charts.LabelBullet());
        levelBullet.locationY = 0.5;
        levelBullet.locationX = 0.5;
        levelBullet.label.text = isNeedToShowLabelsInside ? `{name}: [bold]${prefix}{value}${suffix}[/]` : `{name}`;
        levelBullet.label.fill = am4core.color("fff");

        let firstDimension = context.settings?.dimensions == undefined ? null : context.settings?.dimensions[0];
        if (firstDimension?.useGradient)
            columnsTemplate.adapter.add("fill", (fill: any, target: any) => {
                return target.dataItem ? this.getThemeColors(context)[target.dataItem.index] : fill;
            });
    }

    setData(context: any) {
        if (context.data) {
            this.chart.data = context.data;
            this.clearBlocks();
            this.updateSettings(context);
            console.log("Treemap data is set");
        }
    }

    updateSettings(context: any) {
        let settings = context?.settings;
        let measureAppearance = settings.measures?.[0];
        let prefix = this.getPrefix(context, measureAppearance, settings);
        let suffix = this.getSuffix(context, measureAppearance, settings);
        let column = this.chart.seriesTemplates?.template?.columns?.template;
        if (column)
            column.tooltipText = `{name}: [bold]${prefix}{value}${suffix}[/]`
    }

    appendDataValidated() {
        this.chart.events.on("datavalidated", (_e: any) => {
            if (this.context?.dimensions?.length > 0) {
                let axes = this.chart.yAxes || this.chart.xAxes || undefined;
                if (!axes || !axes.getIndex) return;

                let valueAxes = axes.getIndex(0)?.className === 'ValueAxis' ? this.chart.yAxes : this.chart.xAxes;

                valueAxes.each((valueAxis: any) => {
                    valueAxis.strictMinMax = false;
                });
            }
        });
    }

    recreate(ref: any, context: any) {
        this.dispose();
        this.createByRef(ref, context);
    }

    clearBlocks() {
        this.selectedBlocks = [];
    } 

    onClickTreeMapHandler(_chart: any, series: any, context: any) {
        if (series.columns && context.onClickColumnCaller && !context.disableInteraction) {
            series.columns.template.events.on('hit', (e: any) => {
                e.target.isActive = !e.target.isActive;
                let block = e.target.dataItem.dataContext.dataContext;
                if (e.target.isActive) {
                    this.selectedBlocks.push(block);
                } else {
                    this.selectedBlocks = this.selectedBlocks.filter(x => x.key0 !== block.key0);
                }

                let clickContext = JSON.parse(JSON.stringify(this.selectedBlocks));
                return context.onClickColumnCaller
                    .invokeMethodAsync(context.onClickMultipleColumnFn, clickContext);
            });
        }
    }

    appendTreeMapColumnActiveState(series: any) {
        let activeState = series.columns.template.states.create("active");
        let baseColor = new am4core.ColorSet().baseColor;
        activeState.properties.fill = "#63FB70";
        activeState.propertiesstroke = baseColor.lighten(-0.5);
    }
}