import { Amcharts } from "./Amcharts";
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

export class AmchartsGaugechart extends Amcharts {

    selectedBlocks = [];
    rangeAxis: any;
    firstColor: any;
    lastColor: any;
    copiedData: any;
    isSubscribed: any;

    createByRef(ref: any, context: any) : any {
        this.applyTheme(context.settings, () => {
            this.create(ref, am4charts.GaugeChart, context);
        });

        this.chart.numberFormatter.numberFormat = "#.";
        this.chart.hiddenState.properties.opacity = 0;
        this.chart.fontSize = 11;
        this.chart.innerRadius = am4core.percent(80);
        this.chart.resizable = true;

        this.createValueAxises();

        this.createRanges(context);
        this.createLabels();
        this.createHand();

        return this;
    }

    //recreate(ref: any, context: any) {
    //    this.dispose();
    //    this.createByRef(ref, context);
    //}

    createValueAxises() {
        let axis = this.chart.xAxes.push(new am4charts.ValueAxis());
        axis.min = 0;
        axis.max = 100;
        axis.strictMinMax = true;
        axis.renderer.radius = am4core.percent(80);
        axis.renderer.inside = true;
        axis.renderer.line.strokeOpacity = 0.1;
        axis.renderer.ticks.template.disabled = false;
        axis.renderer.ticks.template.strokeOpacity = 1;
        axis.renderer.ticks.template.strokeWidth = 0.5;
        axis.renderer.ticks.template.length = 5;
        axis.renderer.grid.template.disabled = true;
        axis.renderer.labels.template.radius = am4core.percent(10);
        axis.renderer.labels.template.fontSize = "1.1em";

        this.rangeAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
        this.rangeAxis.min = 0;
        this.rangeAxis.max = 100;
        this.rangeAxis.strictMinMax = true;
        this.rangeAxis.renderer.labels.template.disabled = true;
        this.rangeAxis.renderer.ticks.template.disabled = true;
        this.rangeAxis.renderer.grid.template.disabled = false;
        this.rangeAxis.renderer.grid.template.opacity = 0.5;
        this.rangeAxis.renderer.labels.template.bent = true;
        this.rangeAxis.renderer.labels.template.fill = am4core.color("#000");
        this.rangeAxis.renderer.labels.template.fontWeight = "bold";
        this.rangeAxis.renderer.labels.template.fillOpacity = 0.7;
    }

    createRanges(context: any) {
        let lang = context?.currentLanguage?.key;
        let { gaugeRanges } = context.settings;
        //if (!gaugeRanges) {
        //    gaugeRanges = context.settings.measures[0].gaugeRanges;
        //}

        const { length } = gaugeRanges;
        if (length > 0) {
            const updatedGaugeRanges = this.makeGradients(gaugeRanges);

            for (let grading of updatedGaugeRanges) {
                const range = this.rangeAxis.axisRanges.create();
                const fill = grading.gradient != null ? grading.gradient : am4core.color(grading.color);

                range.axisFill.fillOpacity = 0.8;
                range.axisFill.zIndex = -1;
                range.axisFill.fill = fill;
                range.grid.strokeOpacity = 0;                
                range.label.inside = true;
                range.label.location = 0.5;
                range.label.inside = true;
                range.label.radius = am4core.percent(10);
                range.label.paddingBottom = -5;
                range.label.fontSize = "1.1em";
                range.gradingId = grading.id;
                range.label.text = this.getTitle(grading.id, context);
                range.value = Math.max(grading.min, 0); 
                range.endValue = Math.min(grading.max, 100);
                range.stroke = fill;
            }
        }

        this.firstColor = gaugeRanges[0].color;
        this.lastColor = gaugeRanges[length - 1].color;
    }

    makeGradients(gaugeRanges: any) {
        let updatedGaugeRanges: { min: any; max: any; gradient: am4core.LinearGradient; title: string; color: any; id: any}[] = [];

        if (gaugeRanges.length == 0) {
            const newGauge = { min: 0, max: 100, gradient: this.makeGradient(this.firstColor, this.lastColor), title: "", color: "black", id: "" };
            updatedGaugeRanges.push(newGauge);
            return updatedGaugeRanges;
        }

        gaugeRanges.forEach((gauge: any, index: number) => {
            const newGauge = { min: Number, max: Number, gradient: am4core.LinearGradient.prototype, title: "", color: "black", id: "" };
            updatedGaugeRanges.push(gauge);

            if (index < gaugeRanges.length - 1) {
                let nextGauge = gaugeRanges[index + 1];
                if (gauge.max < nextGauge.min || nextGauge.min == 0) {
                    newGauge.max = nextGauge.min == 0 ? 100 : nextGauge.min;
                    newGauge.min = gauge.max;
                    newGauge.gradient = this.makeGradient(gauge.color, nextGauge.color);
                    updatedGaugeRanges.push(newGauge);
                }
            }
        });
        
        return updatedGaugeRanges;
    }

    makeGradient(first: any, last: any): am4core.LinearGradient {
        const gradient = new am4core.LinearGradient();
        gradient.stops.push({ color: am4core.color(first) })
        gradient.stops.push({ color: am4core.color(last) })
        return gradient;
    }

    createLabels() {

        let label = this.chart.radarContainer.createChild(am4core.Label);
        label.isMeasured = false;
        label.fontSize = "6em";
        label.x = am4core.percent(50);
        label.paddingBottom = 15;
        label.horizontalCenter = "middle";
        label.verticalCenter = "bottom";
        label.fill = "#000000";

        let label2 = this.chart.radarContainer.createChild(am4core.Label);
        label2.isMeasured = false;
        label2.fontSize = "2em";
        label2.horizontalCenter = "middle";
        label2.verticalCenter = "bottom";
    }

    createHand() {
        let hand = this.chart.hands.push(new am4charts.ClockHand());
        hand.axis = this.rangeAxis;
        hand.innerRadius = am4core.percent(55);
        hand.startWidth = 8;
        hand.pin.disabled = true;
        hand.fill = am4core.color("#444");
        hand.stroke = am4core.color("#000");
    }

    setData(context: any) {
        if (context.data || this.copiedData) {
            this.chart.data = context.data;
            this.copiedData = context.data;
            this.updateValue(context);
            //this.updateRangeTitles(context);
        }
    }

    lookUpGrade(value: any, gauges: any) {
        for (let gauge of gauges) {
            if (gauge.min < value && gauge.max >= value)
                return gauge;
        }
        return null;
    }

    updateValue(context: any) {
        let resultName = context.measures.find(Boolean)?.resultName;
        if (resultName) {
            let value;
            if (this.chart.data.length == 0)
                value = this.copiedData[0][resultName];
             else
                value = this.chart.data[0][resultName];

            this.chart.hands.values[0].value = value;

            let label = this.chart.radarContainer.children.values.find((m: any) => m.className == "Label");           
            label.text = this.getLabelText(value);
        }

    }

    getLabelText(value: any) {
        let numberFormat = this.context.settings.numberFormat;
        let fractionDigits = numberFormat == null ? 0 : numberFormat?.split("0").length - 1;
        let percentSymbol = numberFormat?.includes('%') ? "%" : "";
        return Intl.NumberFormat(navigator.language, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }).format(value) + percentSymbol;
    }

    updateRangeTitles(context: any) {
        this.rangeAxis.axisRanges.values.forEach((range: any) => {
            range.label.text = this.getTitle(range.gradingId, context) ?? range.label.text;
        });
    }

    getTitle(gradingId: string, context: any) {
        let lang = context?.currentLanguage?.key;

        let { gaugeRanges } = context.settings;
        //if (!gaugeRanges) {
        //    gaugeRanges = context.settings.measures[0].gaugeRanges;
        //}

        let gaugeRange = gaugeRanges?.find((x: any) => x.id == gradingId);
        let translation = gaugeRange?.titleGlossary?.translations?.keywords?.find((x: any) => x.language === lang);

        return translation?.text ?? gaugeRange?.title;
    }

    clearBlocks() {
        this.selectedBlocks = [];
    }

}