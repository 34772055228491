import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { Amcharts } from './Amcharts';
import * as moment from 'moment'

export class AmchartsTimechart extends Amcharts {

    constructor() {
        super();

        this.whenReady((_ev: any) => {
            this.chart.events.on("datavalidated", (_e: any) => {
                this.chart.yAxes.each((valueAxis: any) => {
                    valueAxis.zoomable = false;
                    valueAxis.toggleZoomOutButton = false;
                });
            });
        });
    }

    createByRef(ref: any, context: any): any {
        if (!context.settings.themeVariant)
            context.settings.themeVariant = 3;

        this.applyTheme(context, () => {
            this.create(ref, am4charts.XYChart, context);
        });

        this.generateAxesByDimensions(context);
        this.generateAxesByMeasures(context);
        this.updateMainAxis(context);

        if (!this.hasDataType(context))
            return this;

        this.generateSeries(context);
        this.syncValueAxes(context);
        this.chart.colors.reset();

        let cursor = new am4charts.XYCursor();
        cursor.behavior = "panXY";
        this.chart.cursor = cursor;

        if (context.settings.showTopScroll)
            this.chart.scrollbarX = new am4core.Scrollbar();

        return this;
    }

    recreate(ref: any, context: any) {
        this.dispose();
        this.createByRef(ref, context);
    }

    generateAxesByDimensions(context: any) {
        for (let i = 0; i < context.dimensions.length; i++) {
            let dimension = context.dimensions[i];
            let dimensionResultName = dimension.resultName || "key" + i;
            if (dimension.dataType === 3)
                this.createCategoryAxis(dimensionResultName, context);
        }
    }

    generateAxesByMeasures(context: any) {
        let isGlobalAxisExist = false;
        let isGlobalDeltaAxisExist = false;

        for (let i = 0; i < context.measures.length; i++) {
            let measure = context.measures[i];
            let measureResultName = measure.resultName || "value" + i;
            let measureAppearance = context.settings.measures?.length
                ? context.settings.measures?.find((m: any) => m.name === measureResultName)
                : {};
            let measureContext = {
                measure,
                measureAppearance,
                settings: context.settings,
                culture: context.culture,
                currentLanguage: context.currentLanguage
            }

            let isDeltaPercent = measure?.valueKind == 2;

            if (isDeltaPercent) {
                if (measureAppearance?.hasOwnAxis || !isGlobalDeltaAxisExist) {
                    this.createDeltaValueAxis(measureContext);
                    isGlobalDeltaAxisExist = true;
                    continue;
                }
            }

            if (measureAppearance?.hasOwnAxis || !isGlobalAxisExist) {
                this.createValueAxis(measureContext);
                isGlobalAxisExist = true;
            }
        }
    }

    generateSeries(context: any) {
        for (let i = 0; i < context.measures.length; i++) {
            let measure = context.measures[i];
            let measureResultName = measure.resultName || "value" + i;

            for (let j = 0; j < context.dimensions.length; j++) {
                let dimension = context.dimensions[j];
                let dimensionResultName = dimension.resultName || "key" + j;
                let isDelta = measure?.valueKind != 0;

                let series = this.createSeries(measureResultName, dimensionResultName, context, null, null, isDelta);
                if (isDelta && measure.isDeltaSync)
                    this.updateDeltaValueAxis(measure.resultName, context);

                this.createTooltip(series, context, dimension);
                this.setSegmentsHit(series, context, dimensionResultName, measureResultName);
            }
        }
    }

    updateMainAxis(context: any) {
        let mainAxis = this.getMainValueAxis(context);
        if (mainAxis)
            mainAxis.keepSelection = true;
    }

    hasDataType(context: any) {
        return context.dimensions.find((dimension: any) => (dimension.dataType === 3)); //date dimension
    }

    setSegmentsHit(series: any, context: any, dimensionResultName: any, measureResultName: any) {
        if (series.segments) {
            series.segments.template.events.on("hit", (ev: any) => {
                let item = ev.target.dataItem.component.tooltipDataItem.dataContext;
                console.log("clicked on " + item[dimensionResultName] + ": " + item[measureResultName]);
                return context.onClickColumnCaller
                    .invokeMethodAsync(context.onClickColumnFn, ev.target.column.dataItem.dataContext)
                    .then((r: any) => console.log(r));
            }, this);
        }
    }

    createCategoryAxis(_dimensionResultName: any, context: any) {
        let categoryAxis = this.chart.xAxes.push(new am4charts.DateAxis());

        this.setCategoryAxisFormatter(categoryAxis, context);
        categoryAxis.skipEmptyPeriods = true;
        categoryAxis.cursorTooltipEnabled = false;
        let dateDimension = context.dimensions.find((d: any) => d.dataType == 3);
        if (dateDimension.binAdditionalLabels > 0) {
            categoryAxis.renderer.labels.template.marginTop = 0;
            categoryAxis.renderer.labels.template.marginBottom = 20;
        }

        this.updateCategoryAxisSettings(categoryAxis, context.settings);

        if (dateDimension && dateDimension.periodComparison && dateDimension.periodComparison > 0)
            categoryAxis.properties["markUnitChange"] = false;

        return categoryAxis;
    }

    setCategoryAxisFormatter(categoryAxis: any, context: any) {
        let dateDimension = context.dimensions.find((d: any) => d.dataType == 3);

        switch (dateDimension.bin) {
            case 6:
                categoryAxis.renderer.labels.template.adapter.add("text", (text: any, target: any) => {
                    if (target.dataItem && target.dataItem.date) {
                        const quarter = Math.floor(target.dataItem.date.getMonth() / 3) + 1;
                        return `Q${quarter}`;
                    }
                    return text;
                });
                break;

            case 5:
                if (dateDimension.binAdditionalLabels == 7) {
                    categoryAxis.dateFormats.setKey("month", "MMM");
                    categoryAxis.periodChangeDateFormats.setKey("month", "MMM");
                }
                //else {
                //    categoryAxis.dateFormats.setKey("month", "[font-size: 12px]MMM");
                //    categoryAxis.periodChangeDateFormats.setKey("month", "[bold]yyyy");
                //}
                categoryAxis.baseInterval = {
                    timeUnit: "month",
                    count: 1
                };
                break;

            case 4:
                categoryAxis.dateFormats.setKey("week", "WW");
                categoryAxis.periodChangeDateFormats.setKey("week", "WW");
                categoryAxis.baseInterval = {
                    timeUnit: "week",
                    count: 1
                };
                let me = this;

                categoryAxis.renderer.labels.template.adapter.add("text", (text: any, target: any) => {
                    if (target.dataItem && target.dataItem.date && me.chart.data.length != 0) {
                        //let isDelta = context.measures[0]?.valueKind != 0;
                        //let measureResultName = isDelta ? "_delta_" + context.measures[0].resultName : context.measures[0].resultName;
                        //let dateDimension = context.dimensions.find((d: any) => d.dataType == 3);
                        //const datesInMilliseconds: number[] = me.chart.data.map((obj: any) => new Date(obj[dateDimension.resultName]).getTime());
                        //const minDate = new Date(Math.min(...datesInMilliseconds));
                        //const minDateYear = minDate.getFullYear();
                        //const minDateMonth = minDate.getMonth() + 1;
                        ///const newDateStr = `${minDateYear}-${minDateMonth < 10 ? '0' : ''}${minDateMonth}-01T00:00:00Z`;                        
                        //me.chart.data.push({ [dateDimension.resultName]: newDateStr, [measureResultName]: null });
                        const weekNumber = categoryAxis.dateFormatter.format(target.dataItem.date, "w");
                        return `W${weekNumber}`;
                    }
                    return '';
                });
                break;

            case 3:
                categoryAxis.dateFormats.setKey("day", "d");
                categoryAxis.periodChangeDateFormats.setKey("day", "d");
                break;

            case 2:
                categoryAxis.dateFormats.setKey("hour", "H");
                categoryAxis.periodChangeDateFormats.setKey("hour", "H");
                categoryAxis.baseInterval = {
                    timeUnit: "hour",
                    count: 1
                };
                break;

            case 1:
                categoryAxis.dateFormats.setKey("minute", "H:mm");
                categoryAxis.periodChangeDateFormats.setKey("minute", "H:mm");
                categoryAxis.baseInterval = {
                    timeUnit: "minute",
                    count: 1
                };
                break;

            default:
                break;
        }
    }

    createValueAxis(context: any) {
        let measure = context?.measure;
        let measureAppearance = context?.measureAppearance;
        let settings = context?.settings;

        let isOpposite = measureAppearance?.hasOwnAxis ? measureAppearance.axisPosition === "opposite" : false;

        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.dataFields.name = measure?.name ?? '';
        valueAxis.dataFields.resultName = measure?.resultName ?? "value0";
        valueAxis.renderer.opposite = isOpposite;
        valueAxis.showOnInit = true;
        valueAxis.skipEmptyPeriods = false;
        valueAxis.title.text = this.translateFromMeasure(context, measureAppearance, "axisTitle") || this.translateFromSettings(context, "valueAxisTitle");
        valueAxis.title.fontWeight = "bold";
        valueAxis.numberFormatter = new am4core.NumberFormatter();
        valueAxis.numberFormatter.numberFormat = this.getMeasureNumberFormat(measureAppearance, settings);
        valueAxis.numberFormatter.bigNumberPrefixes = this.getBigNumberPrefixes(measureAppearance, settings);
        valueAxis.renderer.minGridDistance = settings.axisGridDistance ?? 40;

        this.showValueAxisTooltip(valueAxis, context.settings.showValueAxisTooltip);
        this.setFixedBigNumberValueAxis(valueAxis, measureAppearance, settings);
        this.setLabelVisibility(valueAxis, settings);

        return valueAxis;
    }

    createDeltaValueAxis(context: any) {
        let valueAxis = this.createValueAxis(context);
        let isDeltaPercent = context.measure.valueKind == 2;

        valueAxis.dataFields.deltaName = "_delta_" + context.measure.resultName;
        valueAxis.dataFields.hasDelta = true;

        if (valueAxis.numberFormatter.numberFormat.includes('a'))
            valueAxis.numberFormatter.numberFormat = "#,###.";

        if (!valueAxis.numberFormatter.numberFormat.endsWith('%') && isDeltaPercent)
            valueAxis.numberFormatter.numberFormat += "%";

        this.showValueAxisTooltip(valueAxis, context.settings.showValueAxisTooltip);
        return valueAxis;
    }

    createSerieses(measures: any, dateFieldName: any) {
        let i = this.chart.series.length;
        let me = this;

        measures.forEach(function (measure: any) {
            let valueFieldName = measure.resultName || "value" + i;
            let isDelta = measure?.valueKind != 0;
            me.createSeries(valueFieldName, dateFieldName, {}, null, null, isDelta);
            i++;
        });
    }

    getSeriesType(measureName: any, settings: any) {
        if (settings && settings.measures) {
            let measureAppearance = settings.measures.find((m: any) => measureName.includes(m.name));
            if (measureAppearance)
                return measureAppearance.seriesType;
        }
    }

    createSeries(valueFieldName: any, dateFieldName: any, context: any, keyDimensionName: any = null, seriesTitle: any = null, isDelta: boolean = false) {
        const definedFieldName = isDelta ? "_delta_" + valueFieldName : valueFieldName;
        const { settings, data, measures, } = context;
        const seriesType = this.getSeriesType(valueFieldName, settings);
        const measure = measures.find((f: any) => f.resultName == valueFieldName);
        const measureAppearance = settings?.measures?.find((m: any) => valueFieldName.includes(m.name));
        const hasOwnAxis = measureAppearance?.hasOwnAxis;
        const additional = { valueFieldName, definedFieldName, dateFieldName, keyDimensionName, seriesTitle }

        let series = this.createSeriesByType(seriesType, measure, measureAppearance, settings, additional);

        if (series instanceof am4charts.LineSeries) {
            this.setSeriesLineProperties(series, data, measureAppearance, additional)
        }
        else if (series instanceof am4charts.ColumnSeries) {
            this.setSeriesColumnProperties(series, data, measureAppearance, additional, settings);
        }

        if (measureAppearance?.bulletsType === "last")
            this.setBulletsVisibility(data, measureAppearance, additional);

        if (this.context.settings.fillBeetwenMeasures.active && definedFieldName == this.context.settings.fillBeetwenMeasures.to) { // to
            series.dataFields.openValueY = this.context.settings.fillBeetwenMeasures.from; // from
            series.fillOpacity = 0.3;
        }

        series.yAxis = this.getAppropriateYAxis(definedFieldName, context, hasOwnAxis, isDelta);
        return series;
    }

    createSeriesByType(seriesType: any, measure: any, measureAppearance: any, settings: any, additional: any) {
        const { definedFieldName, dateFieldName, valueFieldName } = additional;

        let series = this.chart.series.push(!seriesType || seriesType === 'line' ? new am4charts.LineSeries() : new am4charts.ColumnSeries());
        series.name = this.getSeriesName(measure);
        series.dataFields.valueY = definedFieldName;
        series.dataFields.dateX = dateFieldName;
        series.dataFields.resultName = valueFieldName;
        series.connect = settings.connectSeries;
        series.numberFormatter = new am4core.NumberFormatter();
        series.numberFormatter.bigNumberPrefixes = this.getBigNumberPrefixes(measureAppearance, settings);

        return series;
    }

    setSeriesLineProperties(series: any, data: any, measureAppearance: any, additional: any) {
        const { valueFieldName } = additional;

        series.strokeWidth = 3;
        series.strokeOpacity = .9;
        series.propertyFields.strokeWidth = 1;
        series.fillOpacity = measureAppearance?.fillArea ? 0.3 : undefined;

        if (this.isThreshold(measureAppearance)) {
            measureAppearance.thresholdType = "datetime";
            this.setLineSegmentsColor(series, data, measureAppearance, additional);
            this.setBulletsVisibility(data, measureAppearance, additional);
            if (measureAppearance.thresholdColorDotted)
                series.propertyFields.strokeDasharray = "_strokeDasharray_" + valueFieldName;
            if (measureAppearance.thresholdColorOpacity)
                series.propertyFields.strokeOpacity = "_strokeOpacity_" + valueFieldName;
            else {
                series.propertyFields.stroke = "_color_" + valueFieldName;
                series.propertyFields.fill = "_color_" + valueFieldName;
            }
        }
    }

    setSeriesColumnProperties(series: any, data: any, measureAppearance: any, additional: any, settings: any) {
        const { valueFieldName } = additional;
        const isStacked = settings?.stacked ?? false;;
        const useCornerRadius = !isStacked;

        series.stacked = isStacked;
        series.fillOpacity = 0.9;
        series.strokeOpacity = .9;
        series.columns.template.propertyFields.strokeWidth = 1;

        this.setCornerRadius(useCornerRadius, series.columns.template.column);
        //if (useCornerRadius) {
        //    series.columns.template.column.adapter.add("cornerRadiusTopLeft", (radius: any, target: any) => (target.dataItem && (target.dataItem.valueY < 0)) ? 0 : 4);
        //    series.columns.template.column.adapter.add("cornerRadiusTopRight", (radius: any, target: any) => (target.dataItem && (target.dataItem.valueY < 0)) ? 0 : 4);
        //    series.columns.template.column.adapter.add("cornerRadiusBottomLeft", (radius: any, target: any) => (target.dataItem && (target.dataItem.valueY > 0)) ? 0 : 4);
        //    series.columns.template.column.adapter.add("cornerRadiusBottomRight", (radius: any, target: any) => (target.dataItem && (target.dataItem.valueY > 0)) ? 0 : 4);

        //    //series.columns.template.column.cornerRadius(4, 4, 0, 0);
        //}

        if (this.isThreshold(measureAppearance)) {
            measureAppearance.thresholdType = "datetime";
            this.setLineSegmentsColor(series, data, measureAppearance, additional);
            this.setBulletsVisibility(data, measureAppearance, additional);
            if (measureAppearance.thresholdColorDotted)
                series.columns.template.propertyFields.strokeDasharray = "_strokeDasharray_" + valueFieldName;

            if (!measureAppearance.thresholdColorOpacity) {
                series.columns.template.propertyFields.stroke = "_color_" + valueFieldName;
                series.columns.template.propertyFields.fill = "_color_" + valueFieldName;
            }

            series.columns.template.adapter.add("fillOpacity", (fillOpacity: any, target: any) => {
                let date = new Date(measureAppearance.thresholdSelectedDate);
                date.setHours(0, 0);
                if (measureAppearance.thresholdColorOpacity && target.dataItem && target.dataItem.dateX >= date)
                    return 0.4;
                else
                    return fillOpacity;
            });
        }
    }

    sortLegend(series: any) {
        series.events.once("ready", (ev: any) => {
            if (!ev.target.legendDataItem)
                return;

            let names = ev.target.chart.series.values.map((x: any) => x.name);
            let sortedNames = names.sort((x: any, y: any) => (x > y ? 1 : -1))
            let name = ev.target.legendDataItem.itemContainer.dataItem.name;
            console.log('name: ' + name);
            let index = sortedNames.indexOf(name);
            console.log('index: ' + index);

            ev.target.legendDataItem.itemContainer.zIndex = index;
            ev.target.chart.legend.children.moveValue(ev.target.legendDataItem.itemContainer, index);
        });
    }

    setLineSegmentsColor(series: any, data: any, measureAppearance: any, additional: any) {
        const { valueFieldName, dateFieldName, keyDimensionName, seriesTitle } = additional;
        if (data) {
            let colorPropName = "_color_" + valueFieldName;
            let opacityPropName = "_strokeOpacity_" + valueFieldName;
            let dasharrayPropName = "_strokeDasharray_" + valueFieldName;
            let dateCompared = new Date(measureAppearance?.thresholdSelectedDate);
            for (let key in data) {
                let value = data[key];
                if (!seriesTitle || value[keyDimensionName] === seriesTitle) {
                    let itemDate = new Date(value[dateFieldName]);
                    if (itemDate >= dateCompared) {
                        value[colorPropName] = measureAppearance?.thresholdColor;
                        value[opacityPropName] = 0.4;
                        value[dasharrayPropName] = "3,3";
                    } else {
                        value[colorPropName] = series.propertyFields.stroke;
                    }
                }
            }
        }
    }

    setBulletsVisibility(data: any, measureAppearance: any, additional: any = null) {
        const { definedFieldName, dateFieldName, keyDimensionName, seriesTitle } = additional;
        if (data) {
            let disablePropName = "_disabled_" + definedFieldName;
            let dateCompared = new Date(measureAppearance.thresholdSelectedDate);
            for (let key in data) {
                let value = data[key];
                if (!seriesTitle || value[keyDimensionName] === seriesTitle) {
                    let itemDate = new Date(value[dateFieldName]);
                    const maxDate = new Date(Math.max(...data.map((item: any) => new Date(item[dateFieldName]))));
                    if (measureAppearance?.bulletsType === "last")
                        value[disablePropName] = itemDate < maxDate;
                    else if (this.isThreshold(measureAppearance))
                        value[disablePropName] = itemDate < dateCompared
                }
            }
        }
    }

    getLabelSize(bin: number): number {
        switch (bin) {
            case 4: //week
                return 2.5;
            case 5: //day
                return 3;
            default:
                return 2;
        }
    }

    setGroupingAxisLabels(context: any, dateDimension: any) {
        let timeDimension = this.context.dimensions?.find((f: any) => f.dataType == 3);
        let dimension = context.settings.dimensions?.length ? context.settings.dimensions?.find((d: any) => d.name == timeDimension?.resultName && d.categoryLabelRotation) : {};
        let showAdditionalLabelStroke = dimension?.showAdditionalLabelStroke;
        let rotation = dimension?.additionalBinLabelRotation;
        let noParentRotation = dimension?.categoryLabelRotation == -1;
        let parentSize = noParentRotation ? 1 : this.getLabelSize(dateDimension.bin);
        let data = context.data.map((a: any) => { return a[dateDimension.resultName]; });
        data.sort((a: any, b: any) => Date.parse(a) - Date.parse(b));

        let minDate = new Date(data[0]);
        let maxDate = new Date(data[data.length - 1]);

        if (dateDimension.binAdditionalLabels == 7) //year
        {
            for (let i = minDate.getFullYear(); i <= maxDate.getFullYear(); i++)
                this.createRange(new Date(i, 0, 1), new Date(i, 11, 31), i, rotation, parentSize, showAdditionalLabelStroke);
        }

        if (dateDimension.binAdditionalLabels == 6) //quarter
        {
            for (let i = minDate.getFullYear(); i <= maxDate.getFullYear(); i++) {
                this.createRange(new Date(i, 0, 1), new Date(i, 3, 0), "Q1", rotation, parentSize, showAdditionalLabelStroke);
                this.createRange(new Date(i, 3, 1), new Date(i, 6, 0), "Q2", rotation, parentSize, showAdditionalLabelStroke);
                this.createRange(new Date(i, 6, 1), new Date(i, 9, 0), "Q3", rotation, parentSize, showAdditionalLabelStroke);
                this.createRange(new Date(i, 9, 1), new Date(i, 12, 0), "Q4", rotation, parentSize, showAdditionalLabelStroke);
            }
        }

        if (dateDimension.binAdditionalLabels == 5) //month
        {
            let uniqueMonths: string[] = [];

            data.map((dateStr: any) => {
                let date = moment(dateStr);
                let monthKey = date.format('YYYY-MM');

                if (!uniqueMonths.some((month) => month === monthKey))
                    uniqueMonths.push(monthKey);

                return null;
            });

            for (const dateStr of uniqueMonths) {
                const date = moment(dateStr);
                const startOfMonth = date.startOf('month').toDate();
                const endOfMonth = date.endOf('month').toDate();
                let monthName = date.toDate().toLocaleDateString(this.getActiveLanguage(), { month: 'short' });
                this.createRange(startOfMonth, endOfMonth, monthName, rotation, parentSize, showAdditionalLabelStroke);
            }
        }

        if (dateDimension.binAdditionalLabels == 4) //week
        {
            for (const dateStr of data) {
                const date = moment(dateStr);
                const startOfWeek = date.startOf('isoWeek').toDate();
                const endOfWeek = date.endOf('isoWeek').toDate();
                const weekNumber = moment(date).isoWeek();

                this.createRange(startOfWeek, endOfWeek, `W${weekNumber}`, rotation, parentSize, showAdditionalLabelStroke);
            }
        }

        if (dateDimension.binAdditionalLabels == 3) //day
        {
            let uniqueDays = new Set(data.map((date: any) => date.toDateString()));
            const uniqueDates = Array.from(uniqueDays).map((dateStr: any) => new Date(dateStr));

            for (const dateStr of uniqueDates) {
                const date = moment(dateStr);
                const startOfDay = date.startOf('day').toDate();
                const endOfDay = date.endOf('day').toDate();
                const dayNumber = moment(date).format('D');
                this.createRange(startOfDay, endOfDay, `${dayNumber}`, rotation, parentSize, showAdditionalLabelStroke);
            }
        }

        if (dateDimension.binAdditionalLabels == 2) //hour
        {
            let uniqueHours = new Set(data.map((date: any) => {
                let newDate = new Date(date);
                newDate.setMinutes(0);
                let time = newDate.toTimeString();
                let day = newDate.toDateString();
                let dateTime = `${day} ${time}`;
                return dateTime;
            }));

            const uniqueHourDates = Array.from(uniqueHours);

            for (const hourDateStr of uniqueHourDates) {
                const dateStr = hourDateStr as string;
                const date = moment(dateStr, 'ddd MMM DD YYYY HH');
                const startOfHour = date.startOf('hour').toDate();
                const endOfHour = date.endOf('hour').toDate();
                const hourNumber = date.format('H');
                this.createRange(startOfHour, endOfHour, `${hourNumber}`, rotation, parentSize, showAdditionalLabelStroke);
            }
        }

        let me = this;
        this.chart.xAxes.values[0].events.on("validated", function () {
            let dateAxis = me.chart.xAxes.values[0];

            if (context.settings.showTopScroll)
                dateAxis.groupData = true;

            let labels = dateAxis.renderer.labels.values;
            let axisRanges = dateAxis.axisRanges.values;
            let isAdditionalLabelDuplicated = axisRanges.some((v: any) => labels.some((l: any) => l.currentText?.toLowerCase() === v.label.currentText?.toLowerCase()));

            if (isAdditionalLabelDuplicated)
                dateAxis.axisRanges.clear();
        });
    }

    getDateOfISOWeek(w: any, y: any) {
        let simple = new Date(y, 0, 1 + (w - 1) * 7);
        let dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4)
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        else
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        return ISOweekStart;
    }

    syncValueAxes(context: any) {
        const values = this.chart.yAxes.values;
        if (values?.length > 0) {
            const mainAxis = this.getMainValueAxis(context);
            this.baseSyncValueAxes(this.chart.yAxes, mainAxis);
        }
    }

    getMainValueAxis(context: any): any {
        const measureAppearancesWithOwnAxes = context?.settings?.measures?.filter((m: any) => m.hasOwnAxis);
        const measureAppearancesWithDeltaAxes = context?.measures?.filter((m: any) => m?.valueKind != 0);

        const yAxesWithMultipleGeneral = this.getMarkedMultipleAxes(this.chart.yAxes, measureAppearancesWithOwnAxes,
            { measuresSettingsWithDeltaAxes: measureAppearancesWithDeltaAxes, mode: "general" });

        const mainAxis = yAxesWithMultipleGeneral.find((axis: any) => (axis != null && axis !== undefined));
        return mainAxis ?? null;
    }

    getAppropriateYAxis(valueFieldName: any, context: any, hasOwnAxis: boolean, isDeltaAxis: boolean) {
        if (hasOwnAxis || isDeltaAxis) {
            return this.getActiveAxis(this.chart.yAxes, valueFieldName);
        } else {
            const measureAppearancesWithOwnAxes = context?.settings?.measures?.filter((m: any) => m.hasOwnAxis);
            const measureAppearancesWithDeltaAxes = context?.measures?.filter((m: any) => m?.valueKind != 0);

            const yAxesWithMultipleSpecial = this.getMarkedMultipleAxes(this.chart.yAxes, measureAppearancesWithOwnAxes,
                { measuresSettingsWithDeltaAxes: measureAppearancesWithDeltaAxes, mode: "special" });

            const appropriateAxis = yAxesWithMultipleSpecial.find((axis: any) => (axis !== null && axis !== undefined));
            return appropriateAxis ?? this.chart.yAxes.values[0];
        }
    }

    getDateTimeFormat(bin: number) {
        switch (bin) {
            case 1:
                return 'dd.MMM yyyy H:mm';
            case 2:
                return 'dd.MMM yyyy H:00';
            case 3:
                return 'dd.MMM yyyy';
            case 4:
                return 'w, yyyy';
            case 5:
                return 'MMM yyyy';
            case 6:
                return 'q, yyyy';
            default:
                return 'yyyy';
        }
    }

    createTooltip(series: any, context: any, dimension: any) {
        let settings = context.settings;
        let dateFormat = this.getDateTimeFormat(dimension.bin);
        let resultFieldName = series.dataFields.resultName;
        let valueFieldName = series.dataFields.valueY;
        let measure = context.measures.find((f: any) => resultFieldName.includes(f.resultName));
        let measureAppearance = settings.measures.find((f: any) => resultFieldName.includes(f.name));
        let isDeltaPercent = measure?.valueKind == 2;
        let signIfNeeded = dimension.bin === 6 ? "Q" : dimension.bin === 4 ? "W" : "";
        let prefix = this.getPrefix(context, measureAppearance, settings);
        let suffix = this.getSuffix(context, measureAppearance, settings);

        this.getMeasureNumberFormat(measureAppearance, settings);
        let numberFormat = this.getMeasureNumberFormat(measureAppearance, settings);

        numberFormat = this.getMeasureNumberFormat(measureAppearance, settings) + (isDeltaPercent && !numberFormat?.endsWith('%') ? "%" : "");

        if (isDeltaPercent && numberFormat.includes('a'))
            numberFormat = "#,###.%";

        series.tooltipText = `${signIfNeeded}{dateX.formatDate('${dateFormat}')}: [bold]${prefix}{valueY.formatNumber('${numberFormat}')}${suffix}[/]`;

        series.minBulletDistance = 15;

        series.tooltip.background.cornerRadius = 2;
        series.tooltip.background.strokeOpacity = 0;
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.label.minWidth = 40;
        series.tooltip.label.minHeight = 40;
        series.tooltip.label.textAlign = "middle";
        series.tooltip.label.textValign = "middle";

        this.setBullets(series, settings, measureAppearance, valueFieldName, numberFormat);
        this.setFixedBigNumberTooltip(series, measureAppearance, settings);
    }

    setBullets(series: any, settings: any, measureAppearance: any, valueFieldName: any, numberFormat: any) {
        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 2;
        bullet.circle.radius = 3;
        bullet.circle.fill = am4core.color("#fff");

        let bullethover = bullet.states.create("hover");
        bullethover.properties.scale = 1.3;
        let prefix = this.getPrefix(this.context, measureAppearance, settings);
        let suffix = this.getSuffix(this.context, measureAppearance, settings);
        let labelBullet = series.bullets.push(new am4charts.LabelBullet());

        if (this.isNeedToShowLabels(this.showLabelsFromApp, this.context.showLabels) || this.context.showLabels) {
            labelBullet.label.text = `${prefix}{valueY.formatNumber('${numberFormat}')}${suffix}`;
            if (measureAppearance?.bulletsType === "all") {
                labelBullet.disabled = false;
            } else if (measureAppearance?.bulletsType === "threshold" || measureAppearance?.bulletsType === "last") {
                labelBullet.disabled = true;
                let disablePropName = "_disabled_" + valueFieldName;
                labelBullet.propertyFields.disabled = disablePropName;
            } else {
                labelBullet.disabled = true;
                labelBullet.propertyFields.disabled = null;
            }

            if (measureAppearance?.bulletsSize === "small") {
                labelBullet.fontSize = 10;
            } else if (measureAppearance?.bulletsSize === "medium") {
                labelBullet.fontSize = 13;
            } else if (measureAppearance?.bulletsSize === "large") {
                labelBullet.fontSize = 16;
            }

            labelBullet.label.adapter.add("verticalCenter", (center: any, target: any) => {
                if (!target.dataItem)
                    return center;

                let values = target.dataItem.values;
                return values.valueY.value > 0
                    ? "bottom"
                    : "top";
            });

            if (measureAppearance?.threshold) {
                labelBullet.label.adapter.add("fill", (_fill: any, target: any) => {
                    let dateCompared = new Date(measureAppearance.thresholdSelectedDate);
                    dateCompared.setHours(0, 0);
                    return target.dataItem.dateX >= dateCompared
                        ? am4core.color(measureAppearance.thresholdColor)
                        : series.properties.stroke;
                });
            }

            this.setFixedBigNumberLabel(labelBullet.label, measureAppearance, settings);
        }
    }

    removeSeries(index: any) {
        if (this.chart.series.length > index)
            this.chart.series.removeIndex(index);
    }
    removeSerieses() {
        while (this.chart.series.length) {
            this.chart.series.removeIndex(0).dispose();
        }
    }

    updateSettings(context: any) {
        super.updateSettings(context);

        let settings = context.settings;

        if (settings) {
            this.updateCategoryAxis(settings);
            this.updateValueAxes(context);
            this.updateMeasures(context);
            this.updateSeries(context);

            if (this.chart.data && this.chart.data.length > 0)
                this.setLegend(settings);

        }
    }

    updateCategoryAxisSettings(categoryAxis: any, settings: any) {
        let cellPadding = this.chart.series.length == 0 ? 0.0 : 0.1;
        categoryAxis.renderer.cellStartLocation = 0 + cellPadding;
        categoryAxis.renderer.cellEndLocation = 1 - cellPadding;

        categoryAxis.title.fontWeight = "bold";

        let timeDimension = this.context.dimensions?.find((f: any) => f.dataType == 3);
        let dimension = settings.dimensions?.length ? settings.dimensions?.find((d: any) => d.name == timeDimension?.resultName && d.categoryLabelRotation) : {};

        let label = categoryAxis.renderer.labels.template;
        let rotation = dimension?.categoryLabelRotation;

        let labelCenter = this.getLabelCenter(label.rotation);
        label.horizontalCenter = labelCenter.horizontalCenter;
        label.verticalCenter = labelCenter.verticalCenter;
        label.location = 0.5;

        categoryAxis.renderer.minGridDistance = dimension?.categoryLabelDistance ?? 30;
        categoryAxis.renderer.labels.template.rotation = rotation;
    }

    updateCategoryAxis(settings: any) {
        if (settings == null) return;
        if (!this.chart.xAxes?.values?.length) return;

        let categoryAxis = this.chart.xAxes.getIndex(0);
        this.updateCategoryAxisSettings(categoryAxis, settings);

        if (this.chart.series.length == 1) {
            let seriesName = this.chart.series.getIndex(0).dataFields.dateX;
            if (settings.dimensions) {
                let dimension = settings.dimensions.find((d: any) => d.name == seriesName);
                if (dimension)
                    categoryAxis.title.text = dimension.title;

            }
        }
        else {
            let dateDimension = settings.dimensions.find((d: any) => d.dataType == 3);
            if (dateDimension && dateDimension.title)
                categoryAxis.title = dateDimension.title;

            else {
                categoryAxis.title.dispose();
                categoryAxis.title = null;
            }
        }
    }

    removeValueAxis(index: any) {
        this.chart.yAxes.removeIndex(index);
    }

    isRequiredRemoveAxis(context: any) {
        let yAxis = this.chart.yAxes?.values?.find((axis: any) => axis.dataFields.resultName === context.measure.resultName);
        return yAxis != null;
    }

    removeValueAxisByResultName(resultName: string) {
        let yAxis = this.chart.yAxes?.values?.find((axis: any) => axis.dataFields.resultName === resultName);
        if (yAxis !== undefined) {
            let index = this.chart.yAxes.indexOf(yAxis);
            this.chart.yAxes.removeIndex(index);
        }
    }

    checkValueAxes(context: any) {
        let measuresCount = context?.measures?.length;
        let valueAxesCount = this.chart.yAxes?.values?.length;

        if (measuresCount > 0 && valueAxesCount === 0) {
            let valueAxis = this.createValueAxis(context);
            this.chart.series.each((s: any) => s.yAxis = valueAxis);
        }
    }

    removeValueAxes() {
        while (this.chart.yAxes.length)
            this.chart.yAxes.removeIndex(0);
    }

    updateValueAxes(context: any) {
        for (let measure of context.measures) {
            let measureAppearance = context.settings.measures?.length
                ? context.settings.measures?.find((m: any) => m.name === measure.resultName)
                : {};
            let isOpposite = measureAppearance?.hasOwnAxis ? measureAppearance.axisPosition === "opposite" : false;
            let measureContext = {
                measure,
                measureAppearance,
                settings: context.settings,
                culture: context.culture,
                currentLanguage: context.currentLanguage
            }

            let yAxis = this.chart.yAxes?.values?.find((axis: any) => axis.dataFields.resultName === measure.resultName);

            if (yAxis === null || yAxis === undefined)
                this.createValueAxis(measureContext);
            else
                yAxis.renderer.opposite = isOpposite;

            this.updateValueAxis(yAxis, context);
        }
    }

    updateValueAxis(_valueAxis: any, context: any) {
        let settings = context.settings || {};

        if (settings == null)
            return;

        settings?.measures.forEach((measureAppearance: any, _i: number) => {
            let valueAxis = this.chart.yAxes?.values?.find((axis: any) => axis.dataFields.resultName === measureAppearance.name);
            if (valueAxis) {
                valueAxis.title.text = this.translateFromMeasure(context, measureAppearance, "axisTitle") || this.translateFromSettings(context, "valueAxisTitle");
                valueAxis.title.fontWeight = "bold";
            }
        });
    }

    updateDeltaValueAxis(resultName: any, context: any) {
        let valueAxis = this.chart.yAxes.values.find((axis: any) => axis.dataFields.resultName === resultName);

        let min = this.getMinValueForDeltaAxes(context);
        if (valueAxis && min !== Infinity)
            valueAxis.min = min;

        let max = this.getMaxValueForDeltaAxes(context);
        if (valueAxis && max !== -Infinity)
            valueAxis.max = max;
    }

    getMinValueForDeltaAxes(context: any): any {
        return this.baseGetMinValueForDeltaAxes(context);
    }

    getMaxValueForDeltaAxes(context: any): any {
        return this.baseGetMaxValueForDeltaAxes(context);
    }

    updateMeasures(context: any) {
        let settings = context.settings || {};

        if (settings == null || settings.dimensions == null || settings.dimensions == null) return;
        let measureAppearances = settings.measures;

        if (settings.dimensions.length > 1) {
            this.updateMeasuresMultiDimensions(context, measureAppearances);
            return;
        }

        this.updateMeasuresSingleDimension(context, measureAppearances);
    }

    updateMeasuresMultiDimensions(context: any, measureAppearances: any) {
        if (measureAppearances?.length && measureAppearances.find((m: any) => m.title !== '')) {
            for (let measureAppearance of measureAppearances) {
                if (measureAppearance.title === "")
                    continue;
                for (let i = 0; i < this.chart.series.length; i++) {
                    this.setSerieName(context, this.chart.series.getIndex(i), measureAppearance);
                }
            }
        }
        else
            this.removeLegend();
    }

    setSerieName(context: any, serie: any, measureAppearance: any) {
        if (serie.dataFields.valueY.includes(measureAppearance.name)) {
            if (measureAppearance.title) {
                serie.name = this.translateFromMeasure(context, measureAppearance, "title");
            }
        }
    }

    updateMeasuresSingleDimension(context: any, measureAppearances: any) {
        for (let measureAppearance of measureAppearances) {
            for (let j = 0; j < this.chart.series.length; j++) {
                let serie = this.chart.series.getIndex(j);
                if (serie.dataFields.resultName === measureAppearance.name)
                    if (measureAppearance.title)
                        serie.name = this.translateFromMeasure(context, measureAppearance, "title");
            }
        }
    }

    updateSeries(context: any) {
        let settings = context.settings;

        if (settings == null) return;
        this.chart.series.each((s: any) => s.stacked = settings.stacked);
        this.chart.invalidateData();
    }

    markActiveByValue(values: any[]) {
        setTimeout(() => {
            this.chart.series.each((s: any) => s.columns.each((c: any, _i: number) => {
                values.forEach((v: any) => {
                    if (v == c.dataItem.categoryX)
                        c.isActive = true;
                })
            }));
        }, 100);
    }

    shouldDefaultToZeroMinValue() {
        return false;
    }

    setData(context: any) {
        if (context.data && context.data.length) {
            let dateDimension = context.dimensions.find((d: any) => d.dataType == 3);
            let data = context.data.map((a: any) => { return a[dateDimension.resultName]; });
            data.sort((a: any, b: any) => Date.parse(a) - Date.parse(b));
            let minDate = new Date(data[0]).setHours(0, 0);
            let maxDate = new Date(data[data.length - 1]).setHours(0, 0);


            this.chart.colors.reset();

            if (dateDimension && dateDimension.periodComparison && dateDimension.periodComparison > 0 && dateDimension.periodComparison != 8)
                this.setPeriodDimensionData(context);
            else if (context.dimensions && context.dimensions.length > 1)
                this.setMultiDimensionData(context);
            else
                this.setSingleDimensionData(context);

            if (dateDimension.binAdditionalLabels > 0) {
                this.setGroupingAxisLabels(context, dateDimension);
                if (context.settings.legendPosition == 'bottom')
                    this.chart.legend.marginTop = 30;
            }

            this.syncValueAxes(context);
        }
    }

    setSingleDimensionData(context: any) {
        let dateDimension = context.dimensions.find((d: any) => d.dataType == 3);
        if (dateDimension) {
            let dateDimensionName = dateDimension.resultName;
            this.removeSerieses();
            for (let measure of context.measures) {
                let isDelta = measure?.valueKind != 0;
                let series = this.createSeries(measure.resultName, dateDimensionName, context, null, null, isDelta);
                if (isDelta && measure.isDeltaSync)
                    this.updateDeltaValueAxis(measure.resultName, context);
                this.createTooltip(series, context, dateDimension);

            }
            let filteredData = context.data.filter((v: any) => v[dateDimensionName] !== null);
            let sortedData = filteredData.sort((a: any, b: any) => a[dateDimensionName] == null ? -1 : a[dateDimensionName].localeCompare(b[dateDimensionName]));
            let isTimeData = dateDimension.bin == 2 || dateDimension.bin == 1;
            this.chart.data = isTimeData ? this.covertDataToDate(sortedData, dateDimensionName) : sortedData;
            this.setLegend(context.settings);
        }
        else {
            this.chart.data = context.data;
            this.removeLegend();
        }
        this.updateMeasures(context);
    }

    covertDataToDate(data: any, dateDimensionName: any) {
        data.forEach((d: any) => {
            const localTime = new Date(d[dateDimensionName]);
            const utcTime = new Date(localTime.getUTCFullYear(), localTime.getUTCMonth(), localTime.getUTCDate(), localTime.getUTCHours(), localTime.getUTCMinutes(), localTime.getUTCSeconds());
            d[dateDimensionName] = utcTime;
        });

        return data;
    }

    setMultiDimensionData(context: any) {
        let data = context.data;

        if (!context.dimensions)
            return;
        let dateDimension = context.dimensions.filter((d: any) => d.dataType == 3)[0]; // Not allowed to have both datetime or both some other type
        if (!dateDimension)
            return;

        let keyDimension = context.dimensions.filter((d: any) => d.dataType !== 3)[0];
        let keyDimension2 = context.dimensions.filter((d: any) => d.dataType !== 3)[1];
        let key0 = keyDimension?.resultName;
        let key1 = keyDimension2?.resultName;

        let dateDimensionName = dateDimension.resultName;

        let series: any = [];
        series = this.pushSeriesFromData(series, data, context, key0, key1);

        let labelOrder = context?.settings?.legendOrder ?? 0;

        if (labelOrder === 1)
            series = series.sort((x: any, y: any) => x.title > y.title ? 1 : -1);
        else if (labelOrder === 2)
            series = series.sort((x: any, y: any) => x.title > y.title ? -1 : 1);

        let transformedData: any = [];
        transformedData = this.transformData(transformedData, data, series, key0, key1, dateDimensionName)

        this.removeSerieses();
        let arr: any[] = [];
        arr = this.createChartSeries(series, context, arr, dateDimension, dateDimensionName);
        this.chart.cursor.snapToSeries = context.settings.showMultiTooltips ? null : arr;

        data.forEach((item: any) => {
            let obj = transformedData.find((d: any) => d[dateDimensionName] === item[dateDimensionName]);
            this.updateTransformedData(item, obj);
        });

        let filteredData = transformedData.filter((v: any) => v[dateDimensionName] !== null);
        let sortedData = filteredData.sort((a: any, b: any) => a[dateDimensionName] == null ? -1 : a[dateDimensionName].localeCompare(b[dateDimensionName]));
        let isTimeData = dateDimension.bin == 2 || dateDimension.bin == 1;
        this.chart.data = isTimeData ? this.covertDataToDate(sortedData, dateDimensionName) : sortedData;
        this.chart.colors.reset();
        this.setLegend(context.settings);
    }

    pushSeriesFromData(series: any, data: any, context: any, key0: any, key1: any) {
        data.forEach((item: any) => {
            let obj = series.find((s: any) => s.title === item[key0]);
            let obj2 = series.find((s: any) => s.title === item[key1]);
            if (!obj) {
                series = this.pushSeries(series, item, context.measures, key0, "");
            }
            if (!obj2 && key1) {
                series = this.pushSeries(series, item, context.measures, key1, "_");
            }
        });
        return series;
    }

    pushSeries(series: any, item: any, measures: any, key: any, underscoreTemplate: any) {
        for (let measure of measures) {
            let resultName = measure.resultName || (underscoreTemplate + "value");
            let isDelta = measure?.valueKind != 0;
            let serie = {
                title: item[key],
                fieldName: isDelta
                    ? "_delta_" + underscoreTemplate + resultName
                    : underscoreTemplate + resultName,
                keyName: key
            };
            series.push(serie);
        }

        return series;
    }

    transformData(transformedData: any, data: any, series: any, key0: any, key1: any, dateDimensionName: any) {
        data.forEach((item: any) => {
            let obj = transformedData.find((d: any) => d[dateDimensionName] == item[dateDimensionName]);
            if (!obj) {
                obj = {};
                obj[dateDimensionName] = item[dateDimensionName];
                transformedData.push(obj);
            }

            Object.keys(item).forEach((key) => {
                obj = this.dataTransform(series, item, obj, key, key0, key1, dateDimensionName);
            });
        });
        return transformedData;
    }

    dataTransform(series: any, item: any, obj: any, key: any, key0: any, key1: any, dateDimensionName: any) {
        if (!(key === dateDimensionName || key === key0 || key === key1)) {
            let field = series.find((s: any) => ((s.title == item[key0]) && (s.fieldName == key)));
            let field2 = series.find((s: any) => ((s.title == item[key1]) && (s.fieldName == key)));
            if (field) {
                let fieldName = item[key0] + ":" + field.fieldName;
                obj[fieldName] = item[key];
            }

            if (field2) {
                let fieldName2 = item[key1] + ":" + field2.fieldName;
                obj[fieldName2] = item[key];
            }
        }
        return obj;
    }

    createChartSeries(series: any, context: any, arr: any, dateDimension: any, dateDimensionName: any) {
        for (let serie of series) {
            let chartSeries = this.createSeries(serie.title + ":" + serie.fieldName, dateDimensionName, context, serie.keyName, serie.title);
            this.createTooltip(chartSeries, context, dateDimension);
            let measureTitle = context.settings.measures.find((d: any) => d.name == serie.fieldName || `_delta_${d.name}` == serie.fieldName)?.title;
            chartSeries.name = serie.title == undefined ? measureTitle : serie.title;
            arr.push(chartSeries);
        }
        return arr;
    }

    updateTransformedData(item: any, obj: any) {
        Object.keys(item).forEach((k) => {
            if (k.includes("_color_") || k.includes("_disabled_") || k.includes("_strokeOpacity_"))
                obj[k] = item[k];

        });
    }

    setPeriodDimensionData(context: any) {
        this.removeSerieses();

        let settings = context.settings;
        let dateDimension = context.dimensions.find((d: any) => d.dataType == 3);
        let dateDimensionName = dateDimension.resultName;

        for (let pivotField of context.pivotFields) {
            let series = this.createSeries(pivotField, dateDimensionName, context);
            this.createTooltip(series, context, dateDimension);
            let numberFormat = this.getMeasureNumberFormat(null, settings);
            series.tooltipText = this.getPivotTooltipText(series, context, pivotField, numberFormat);
            series.name = pivotField;
        }

        let sortedData = context.data.sort((a: any, b: any) => a[dateDimensionName] == null ? -1 : a[dateDimensionName].localeCompare(b[dateDimensionName]));
        this.chart.data = sortedData;
        this.setLegend(context.settings);
    }

    getPivotTooltipText(series: any, context: any, pivotField: any, numberFormat: any) {
        let settings = context?.settings;
        let resultFieldName = series?.dataFields?.resultName;
        let measureAppearance = settings?.measures?.find((f: any) => resultFieldName?.includes(f.name));
        let prefix = this.getPrefix(context, measureAppearance, settings);
        let suffix = this.getSuffix(context, measureAppearance, settings);
        return `${pivotField}: [bold]${prefix}{valueY.formatNumber('${numberFormat}')}${suffix}[/]`;
    }

    extractSeriesDatas(datas: any, keyName: any, valueName: any) {
        let result = [];
        for (let data of datas) {
            let item = {};
            (item as any).keyName = data[keyName];
            (item as any).valueName = data[valueName];
            result.push(item);
        }
        return result;
    }

    createRange(from: any, to: any, label: any, rotation: any, parentSize: number, showAdditionalLabelStroke?: any) {
        let range = this.chart.xAxes.values[0].axisRanges.create();
        const noRotation = rotation == -1;
        const size = noRotation ? 0 : 1;

        range.date = from;
        range.endDate = to;
        range.label.text = label;
        range.label.adapter.remove("text");
        range.label.rotation = noRotation ? 0 : rotation;
        range.label.fontSize = 14;
        range.label.dy = (size + parentSize) * 10;
        range.grid.strokeOpacity = showAdditionalLabelStroke ? 1 : 0;
    }

    isThreshold(measureAppearance: any) {
        if (measureAppearance?.threshold) {
            if (measureAppearance?.thresholdSelectedDate)
                return true;
            else
                return false;

        } else
            return false;
    }

    removeCategoryAxis(index: any) {
        this.chart.xAxes.removeIndex(index);
    }
}